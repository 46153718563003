import React from 'react';

export const ICON_REFRESH = (

<svg
width="20"
height="20"
viewBox="0 0 24 24"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
d="M8 18L12 22L16 18H8Z"
fill="currentColor"
/>
<path
d="M8 6L12 2L16 6H8Z"
fill="currentColor"
/>
<rect x="5" y="11" width="14" height="2" rx="1" fill="currentColor" />
</svg>
);

export default ICON_REFRESH;
