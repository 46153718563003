import React from "react";
import PropTypes from "prop-types";
import TypographyComponent from "../typography/TypographyComponent";
import useCustomIntl from "../../../hook/customHook";
import { TEXT } from "../typography/typographyConstants";
import style from "./Modal.module.css";
import { Close } from "@mui/icons-material";
import ButtonComponent from "../button/ButtonComponent";
import {BUTTON} from "../button/buttonConstants";
function ModalComponent({ title, body, handleClose, buttonAcceptText, buttonCancelText, handleSave, size }) {
  const { formatMessage } = useCustomIntl();

  return (
      <section className={style.over} data-tema-oscuro={false}>
        <div className={style.container} data-tema-oscuro={false}>
          {title && (
              <div className={style.header} data-tema-oscuro={false}>
                <TypographyComponent text={formatMessage(title)} variant={TEXT.VARIANT_H2}/>
              </div>
          )}
          <div className={style.body}>{body}</div>
          <div className={style.buttons}>
            {buttonCancelText && (
                <div className={style.iconButton}>
                  <ButtonComponent
                      text={buttonCancelText}
                      variant={BUTTON.VARIANT_SECONDARY}
                      onClick={handleClose}
                  />
                </div>
            )}
            <div className={buttonCancelText ? style.iconButton : style.iconButtonAccept}>
              <ButtonComponent
                  text={buttonAcceptText}
                  variant={BUTTON.VARIANT_SECONDARY}
                  onClick={handleSave}
              />
            </div>
          </div>
        </div>
      </section>
  );
}

ModalComponent.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  textButtonAction: PropTypes.string,
};

export default ModalComponent;
