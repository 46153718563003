import React, { useState, useEffect } from "react";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ButtonComponent from "../button/ButtonComponent";
import { BUTTON_DISABLED_BY_SESSION_STATUS } from "../../../translations/constans";
import Tooltip from "@mui/material/Tooltip";
import { useIntl } from "react-intl";
import style from "./TimeInput.module.css";
import { getObjectTime } from "../../../utils/utils";
import TimeComponent from "../../common/time/TimeComponent";

function TimeInput({
  initialMilliseconds,
  timer,
  handleSave,
  handleTimeChange,
  onGetTimePlayer,
  onTimeChange,
  buttonText,
  isCreating = false,
  editableByStatus,
  buttonDisabled,
  statusReviewedPublished,
}) {
  const intl = useIntl();
  const { hours, minutes, seconds, milliseconds } = getObjectTime(initialMilliseconds);
  const handleFixHourFromPlayer = (e) => {
    e.stopPropagation();
    const newMs = onGetTimePlayer() * 1000;
    handleTimeChange(Math.round(newMs));
  };
  const handleHourChange = (e) => {
    e.stopPropagation();
    const val = Math.max(0, Math.min(23, Number(e.target.value)));
    const newTime = val * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds;
    handleTimeChange(newTime);
  };

  const handleMinuteChange = (e) => {
    e.stopPropagation();
    const val = Math.max(0, Math.min(59, Number(e.target.value)));
    const newTime = hours * 3600000 + val * 60000 + seconds * 1000 + milliseconds;
    handleTimeChange(newTime);
  };

  const handleSecondChange = (e) => {
    e.stopPropagation();
    const val = Math.max(0, Math.min(59, Number(e.target.value)));
    const newTime = hours * 3600000 + minutes * 60000 + val * 1000 + milliseconds;
    handleTimeChange(newTime);
  };

  const handleMillisecondChange = (e) => {
    e.stopPropagation();
    const val = Math.max(0, Math.min(999, Number(e.target.value)));
    const newTime = hours * 3600000 + minutes * 60000 + seconds * 1000 + val;
    handleTimeChange(newTime);
  };

  const handleOnBlurTimeInput = (e) => {
    e.stopPropagation();
    const newTime = hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds;
    handleSave(newTime);
  };

  return (
    <div className={isCreating ? style.isCreating : style.isNotCreating}>
      {statusReviewedPublished ? (
        <div className={style.timerStyle}>
          <TimeComponent timeMilliseconds={initialMilliseconds} timeText={buttonText} />
        </div>
      ) : (
        <>
          <div className={style.hourButton}>
            <Tooltip
              title={statusReviewedPublished ? intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS }) : ""}
            >
              <div>
                <ButtonComponent
                  onClick={handleFixHourFromPlayer}
                  text={buttonText}
                  icon={<MoreTimeIcon />}
                  disabled={!editableByStatus}
                  variant={buttonDisabled}
                />
              </div>
            </Tooltip>
          </div>
          <div className={style.inputs}>
            <input
              disabled={statusReviewedPublished}
              className={style.timerCellStyle}
              type="number"
              value={hours}
              /*onBlur={handleOnBlurTimeInput}*/
              onChange={handleHourChange}
            />
            {": "}
            <input
              disabled={statusReviewedPublished}
              className={style.timerCellStyle}
              type="number"
              value={minutes}
              /*onBlur={handleOnBlurTimeInput}*/
              onChange={handleMinuteChange}
            />
            {": "}
            <input
              disabled={statusReviewedPublished}
              className={style.timerCellStyle}
              type="number"
              value={seconds}
              /*onBlur={handleOnBlurTimeInput}*/
              onChange={handleSecondChange}
            />
            {". "}
            <input
              disabled={statusReviewedPublished}
              className={style.timerCellStyle}
              type="number"
              value={milliseconds}
              /*onBlur={handleOnBlurTimeInput}*/
              onChange={handleMillisecondChange}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default TimeInput;
