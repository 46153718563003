import React from "react";
import TypographyComponent from "../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_H1 } from "../../../../shared/typography/constants";
import { useIntl } from "react-intl";
import { PLENARY__SESSIONS__RESOLVE_THE_ERROR } from "../../../../../translations/constans";
import styles from "./InErrorComponent.module.css";

const InErrorComponent = () => {
  const intl = useIntl();
  return (
    <TypographyComponent
      className={styles.message}
      text={intl.formatMessage({ id: PLENARY__SESSIONS__RESOLVE_THE_ERROR })}
      variant={TEXT_VARIANT_H1}
    />
  );
};

export default InErrorComponent;
