import React, { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import { createAgendaPointService, getMembersService, saveAgendas } from "../../../../../../../services/commonservices";
import OrganizationParticipantsComponent from "./components/organizationParticipantsComponent/OrganizationParticipantsComponent";
import CreateOrEditParticipantForm from "./components/createOrEditParticipantForm/CreateOrEditParticipantForm";
import { useIntl } from "react-intl";
import { sortedParticipantsByName } from "../../../../../../../utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CreateOrEditParticipantModalComponent({
  openedModal,
  newOrder,
  plenarySessionId,
  seats,
  onClose,
  onReloadData,
  setInterventions,
  setAllChangesParticipants,
  handleSetReloadInterventionsAndMinutes,
  participants,
}) {
  const intl = useIntl();
  const [descriptions, setDescriptions] = useState({});
  const [organizationMembers, setOrganizationMembers] = useState(null);

  const handleOrganizationsMembers = (response) => {
    setOrganizationMembers(sortedParticipantsByName(response.data));
  };
  useEffect(() => {
    if (openedModal) {
      getMembersService(handleOrganizationsMembers);
    }
  }, [openedModal]);
  const handleClose = () => {
    onClose(false);
  };
  const handleDescriptionChange = (description, language) => {
    const newDescriptions = { ...descriptions };
    newDescriptions[language] = description;
    setDescriptions(newDescriptions);
  };
  const handleCreateOrderSuccess = () => {
    onReloadData();
    onClose();
  };
  const handleCreateOrder = () => {
    const body = {
      item_description_translations: descriptions,
      order: newOrder,
    };
    createAgendaPointService(plenarySessionId, body, handleCreateOrderSuccess);
  };

  return (
    <div>
      <Modal
        open={openedModal.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {openedModal.isNew || openedModal.participant ? (
            <CreateOrEditParticipantForm
              seats={seats}
              onCloseForm={handleClose}
              participant={openedModal.participant}
              plenarySessionId={plenarySessionId}
              onReloadData={onReloadData}
              handleSetReloadInterventionsAndMinutes={handleSetReloadInterventionsAndMinutes}
              setAllChangesParticipants={setAllChangesParticipants}
            />
          ) : (
            <OrganizationParticipantsComponent
              onCloseForm={handleClose}
              organizationMembers={organizationMembers}
              plenarySessionId={plenarySessionId}
              onReloadData={onReloadData}
              setInterventions={setInterventions}
              setAllChangesParticipants={setAllChangesParticipants}
              handleSetReloadInterventionsAndMinutes={handleSetReloadInterventionsAndMinutes}
              participantsAdded={participants}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default CreateOrEditParticipantModalComponent;
