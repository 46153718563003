import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Pagination } from "@material-ui/lab";
import { useIntl } from "react-intl";

import style from "./PlenaryListTable.module.css";
import RowComponent from "./components/rowComponent/RowComponent";
import { TEXT_VARIANT_SMALL } from "../../../../../shared/typography/constants";
import Typography from "../../../../../shared/typography/Typography";
import { COMMON__TOTAL_RESULTS, PLENARY__SESSIONS__ACTION } from "../../../../../../translations/constans";
import { getPlenarySessions, getSessionStatus } from "../../../../../../services/commonservices";
import { SESSION_PLENARY__LIMIT } from "../../../../../../utils/constants";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const PlenaryListTableComponent = ({
  rows = [],
  columns = [],
  called,
  onReloadData,
  handleOffset,
  paginationTotalResults,
  limit,
}) => {
  const intl = useIntl();

  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    if (Math.ceil(paginationTotalResults / limit) >= newPage) {
      setPage(newPage - 1);
    }
  };
  useEffect(() => {
    handleOffset(page);
  }, [page]);
  useEffect(() => {
    setPage(0);
  }, [paginationTotalResults]);

  return (
    <div>
      <TableContainer sx={{ minHeight: 320 }}>
        <Table aria-label="collapsible table" className={style.table}>
          <TableHead>
            <TableRow className={style.header}>
              {columns.map((column) => {
                return column.field === "status" ? (
                  <TableCell
                    style={{ flex: column.flex }}
                    className={style.tableHeader}
                    key={column.field}
                    align="center"
                  >
                    <Typography idText={column.headerName} variant={TEXT_VARIANT_SMALL} />
                  </TableCell>
                ) : (
                  <TableCell style={{ flex: column.flex }} className={style.tableHeader} key={column.field}>
                    <Typography idText={column.headerName} variant={TEXT_VARIANT_SMALL} />
                  </TableCell>
                );
              })}
              <TableCell className={style.tableHeader}>
                <Typography
                  idText={intl.formatMessage({ id: PLENARY__SESSIONS__ACTION })}
                  variant={TEXT_VARIANT_SMALL}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={style.tableBody}>
            {rows?.map((row) => (
              <RowComponent key={row.id} row={row} columns={columns} onReloadData={onReloadData} />
            ))}
            {!!called && !rows?.length && (
              <div className={style.noResultsContainer}>
                <Typography idText={"no_results_found"} translate />
              </div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={style.paginationBlock}>
        <div>
          {!!rows?.length && (
            <Typography
              idText={`${intl.formatMessage({ id: COMMON__TOTAL_RESULTS })} ${paginationTotalResults || " - "}`}
              variant={TEXT_VARIANT_SMALL}
            />
          )}
        </div>
        <div className={style.paginationBlock}>
          <Pagination
            count={Math.ceil(paginationTotalResults / limit)}
            showFirstButton
            showLastButton
            onChange={handleChangePage}
            page={page + 1}
          />
        </div>
      </div>
    </div>
  );
};

export default PlenaryListTableComponent;
