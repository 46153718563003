import React, { useState, useEffect } from "react";
import { getSessionTranscriptions, updateTranscriptionService } from "../../../../../../../services/commonservices";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { I18N_CONFIG } from "../../../../../../../utils/constants";
import { useIntl } from "react-intl";
import {
  COMMON_CANCEL,
  COMMON__EDIT,
  COMMON_SAVE,
  PLENARY_SESSIONS__TRANSCRIPTIONS,
  COMMON_LANGUAGE,
  BUTTON_DISABLED_BY_SESSION_STATUS,
} from "../../../../../../../translations/constans";
import TypographyComponent from "../../../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_H2, TEXT_VARIANT_P, TEXT_VARIANT_SPAN } from "../../../../../../shared/typography/constants";
import ButtonComponent from "../../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../../shared/button/buttonConstants";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { SaveOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

const TranscriptionEditorComponent = ({
  plenarySessionId,
  editableByStatus,
  buttonDisabled,
  statusReviewedPublished,
  videoRef,
}) => {
  const intl = useIntl();
  const [transcriptions, setTranscriptions] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [editedText, setEditedText] = useState("");
  const [languageSelected, setLanguageSelected] = useState(null);
  const [transitionShow, setTransitionShow] = useState("subtitle");

  const availableLanguages = useSelector((state) => state.common.availableLanguages);
  const defaultLanguage = useSelector((state) => state.common.language);
  const handleLanguageSelectedChange = (newLanguageSelected) => {
    setLanguageSelected(newLanguageSelected);
  };

  const handleTransitionsChange = (transcriptions) => {
    setTranscriptions(transcriptions);
  };

  useEffect(() => {
    if (languageSelected !== defaultLanguage) {
      handleLanguageSelectedChange(defaultLanguage);
    }
  }, [defaultLanguage]);

  const getData = () => {
    getSessionTranscriptions(plenarySessionId, (response) => handleTransitionsChange(response.data));
  };

  useEffect(() => {
    getData();
  }, [plenarySessionId]);

  const handleTransitionTextChange = (id, text) => {
    let newTranscriptions = [...transcriptions];
    const newTranscription = newTranscriptions.find((transcription) => transcription.id === id);
    newTranscription.transcription_translations[languageSelected] = text;
    handleTransitionsChange(newTranscriptions);
  };
  const handleTransitionShowChange = (event) => {
    setTransitionShow(event.target.value);
  };
  const updateTranscriptionSuccess = () => {
    setIsEditing(null);
    getData();
  };
  const updateTranscription = () => {
    updateTranscriptionService(plenarySessionId, transcriptions, updateTranscriptionSuccess);
  };
  const formatTime = (milliseconds) => {
    let hours = Math.floor(milliseconds / 3600000);
    let minutes = Math.floor((milliseconds % 3600000) / 60000);
    let seconds = Math.floor((milliseconds % 60000) / 1000);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return `${hours}:${minutes}:${seconds}`;
  };

  const handleCancel = () => {
    setIsEditing(null);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "var(--spacing-xl)", padding: "var(--spacing-xl)" }}>
      <TypographyComponent
        text={intl.formatMessage({ id: PLENARY_SESSIONS__TRANSCRIPTIONS })}
        variant={TEXT_VARIANT_H2}
      />
      <div>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label"> {intl.formatMessage({ id: COMMON_LANGUAGE })} </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={languageSelected || defaultLanguage}
            label="Idioma"
            onChange={(event) => handleLanguageSelectedChange(event.target.value)}
          >
            {availableLanguages &&
              availableLanguages.map((language) => (
                <MenuItem key={language} value={language}>
                  {intl.formatMessage({ id: I18N_CONFIG[language].label })}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="subtitle"
            name="radio-buttons-group"
            value={transitionShow}
            onChange={handleTransitionShowChange}
          >
            <FormControlLabel value="text" control={<Radio />} label="Modo texto" />
            <FormControlLabel value="subtitle" control={<Radio />} label="Modo subtitulos" />
          </RadioGroup>
        </FormControl>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
        {transcriptions.map((transcription, index) => {
          const highlight =
            transcription.init_time < videoRef.current?.getCurrentTime() &&
            transcription.end_time > videoRef.current?.getCurrentTime();
          return (
            <div
              key={transcription.id}
              style={{
                padding: "0.5rem",
                display: "flex",
                flexDirection: `${isEditing ? "column" : "row"}`,
                borderRadius: 4,
                ...(highlight && {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                  color: "var(--color-primary)",
                }),
              }}
            >
              {transitionShow === "subtitle" && (
                <TypographyComponent
                  text={`${formatTime(transcription.init_time)}-${formatTime(transcription.end_time)}:`}
                  variant={TEXT_VARIANT_SPAN}
                />
              )}
              {isEditing ? (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <textarea
                    defaultValue={transcription.transcription_translations[languageSelected]}
                    value={transcription.transcription_translations[languageSelected]}
                    style={{
                      border: "1px solid var(--color-primary)",
                      borderRadius: 8,
                      padding: "5px 10px",
                    }}
                    onChange={(event) => handleTransitionTextChange(transcription.id, event.target.value)}
                  />
                </div>
              ) : (
                <TypographyComponent
                  text={transcription.transcription_translations[languageSelected]}
                  variant={TEXT_VARIANT_P}
                  style={{ marginLeft: "var(--spacing-sm)", ...(highlight && { color: "var(--color-primary)" }) }}
                />
              )}
            </div>
          );
        })}
      </div>
      {isEditing ? (
        <>
          <div
            style={{
              display: "flex",
              gap: 20,
              width: "100%",
            }}
          >
            <ButtonComponent
              onClick={handleCancel}
              text={intl.formatMessage({ id: COMMON_CANCEL })}
              variant={BUTTON.VARIANT_DANGER}
              icon={<HighlightOffIcon />}
            />

            <ButtonComponent
              onClick={updateTranscription}
              text={intl.formatMessage({ id: COMMON_SAVE })}
              variant={BUTTON.VARIANT_SECONDARY}
              icon={<SaveOutlined />}
            />
          </div>
        </>
      ) : (
        <Tooltip title={statusReviewedPublished ? intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS }) : ""}>
          <div>
            <ButtonComponent
              onClick={() => setIsEditing(true)}
              text={intl.formatMessage({ id: COMMON__EDIT })}
              disabled={!editableByStatus}
              variant={buttonDisabled}
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default TranscriptionEditorComponent;
