import React from "react";
import SessionInformationComponent from "../sessionInformationComponent/SessionInformationComponent";
import AgendaConfigurationComponent from "../agendaConfigurationComponent/AgendaConfigurationComponent";
import ParticipantsConfigurationComponent from "../participansConfigurationComponent/ParticipantsConfigurationComponent";
import DocumentAreaComponent from "../documentAreacComponent/DocumentAreaComponent";
import StatusChangeComponent from "../plenaryDetailMinuteTranscriptionComponent/components/statusChangeComponent/StatusChangeComponent";

const InCreatedAndScheduled = ({ plenarySessionId, sessionData, onChangeOrder, onReloadData, editableByStatus }) => {
  return (
    <>
      <SessionInformationComponent
        plenarySessionId={plenarySessionId}
        sessionData={sessionData}
        onReloadData={onReloadData}
        editableByStatus={editableByStatus}
      />
      <AgendaConfigurationComponent
        agendaItems={sessionData?.agenda}
        plenarySessionId={plenarySessionId}
        onChangeOrder={onChangeOrder}
        onReloadData={onReloadData}
        editableByStatus={editableByStatus}
        handleSetReloadPointsAndMinutes={onReloadData}
      />
      <ParticipantsConfigurationComponent
        roomId={sessionData?.plenary_room_id}
        plenarySessionId={plenarySessionId}
        onReloadData={onReloadData}
        editableByStatus={editableByStatus}
      />
      <DocumentAreaComponent
        onReloadData={onReloadData}
        documents={sessionData?.files}
        isCreatedAndScheduled={true}
        editableByStatus={editableByStatus}
        sessionData={sessionData}
      />
    </>
  );
};

export default InCreatedAndScheduled;
