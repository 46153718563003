import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import caFlag from "../../images/flag_ca.svg";
import enFlag from "../../images/flag_uk.svg";
import glFlag from "../../images/flag_gl.png";
import esFlag from "../../images/flag_es.png";

const imgSrc = {
  ca: caFlag,
  en: enFlag,
  gl: glFlag,
  es: esFlag,
};

const useStyles = makeStyles(() => ({
  root: {
    height: 20,
    width: 20,
    marginLeft: 16,
    marginRight: 6,
  },
  img: {
    height: "100%",
    width: "100%",
  },
}));

const FlagIconComponent = ({ flag }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img alt={`${flag} flag`} className={classes.img} src={imgSrc[flag]} />
    </div>
  );
};

FlagIconComponent.propTypes = {
  flag: PropTypes.string.isRequired,
};

export default FlagIconComponent;
