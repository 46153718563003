import React, { useState } from "react";
import SessionInformationComponent from "../sessionInformationComponent/SessionInformationComponent";
import VideoAreaComponent from "../inDirectComponent/components/videoAreaComponent/VideoAreaComponent";
import PlenaryDetailMinuteTranscriptionComponent from "../plenaryDetailMinuteTranscriptionComponent/PlenaryDetailMinuteTranscriptionComponent";
import DocumentAreaComponent from "../documentAreacComponent/DocumentAreaComponent";
import {
  SESSION__PLENARY__STATUS__FINISHED,
  SESSION__PLENARY__STATUS__IN_REVIEW,
  SESSION__PLENARY__STATUS__PUBLISHED,
  SESSION__PLENARY__STATUS__REVIEWED,
  SESSION__PLENARY__STATUS__STARTED,
  SESSION__PLENARY__STATUS__STARTING,
} from "../../../../../utils/constants";
import style from "./InReviewAndReviewDetailComponent.module.css";
import VideoPlayerComponent from "../plenaryDetailMinuteTranscriptionComponent/components/videoPlayerComponent/VideoPlayerComponent";
import AgendaComponent from "../inDirectComponent/components/orderAreaComponent/components/agendaComponent/AgendaComponent";
import AgendaConfigurationComponent from "../agendaConfigurationComponent/AgendaConfigurationComponent";
import OrderAreaComponent from "../inDirectComponent/components/orderAreaComponent/OrderAreaComponent";
import { Paper } from "@mui/material";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

const InReviewAndReviewDetailComponent = ({
  plenarySessionId,
  plenaryTitle = "",
  sessionData,
  videoRef,
  onReloadData,
  editableByStatus,
}) => {
  const [reloadPointsAndMinutes, setReloadPointsAndMinutes] = useState(true);
  const [reloadInterventionsAndMinutes, setReloadInterventionsAndMinutes] = useState(true);
  const handleSetReloadPointsAndMinutes = () => {
    setReloadPointsAndMinutes(false);
    onReloadData();
  };
  const handleSetReloadInterventionsAndMinutes = () => {
    setReloadInterventionsAndMinutes(false);
    onReloadData();
  };
  const handleSetReloadPointsAndInterventionsAndMinutes = () => {
    setReloadPointsAndMinutes(false);
    setReloadInterventionsAndMinutes(false);
    onReloadData();
  };

  const showVideoPlayer = !!sessionData?.video?.url || !!sessionData?.video?.vod_url;
  const URL_Video = !!sessionData?.video?.vod_url ? sessionData?.video?.vod_url : sessionData?.video?.url;

  return (
    <>
      <SessionInformationComponent
        plenarySessionId={plenarySessionId}
        sessionData={sessionData}
        onReloadData={onReloadData}
        editableByStatus={editableByStatus}
      />
      <VideoAreaComponent
        plenarySessionId={plenarySessionId}
        sessionData={sessionData}
        url={URL_Video}
        videoRef={videoRef}
        onReloadData={onReloadData}
        editableByStatus={editableByStatus}
        showVideoPlayer={showVideoPlayer}
      />
      <PlenaryDetailMinuteTranscriptionComponent
        plenarySessionId={plenarySessionId}
        plenaryTitle={plenaryTitle}
        sessionData={sessionData}
        videoRef={videoRef}
        onReloadData={onReloadData}
        editableByStatus={editableByStatus}
        reloadPointsAndMinutes={reloadPointsAndMinutes}
        setReloadPointsAndMinutes={setReloadPointsAndMinutes}
        reloadInterventionsAndMinutes={reloadInterventionsAndMinutes}
        setReloadInterventionsAndMinutes={setReloadInterventionsAndMinutes}
      />
      {sessionData?.status === SESSION__PLENARY__STATUS__IN_REVIEW && (
        <OrderAreaComponent
          plenaryTitle={sessionData?.title}
          plenarySessionId={plenarySessionId}
          editableByStatus={editableByStatus}
          onReloadData={onReloadData}
          handleSetReloadPointsAndMinutes={handleSetReloadPointsAndMinutes}
          handleSetReloadInterventionsAndMinutes={handleSetReloadInterventionsAndMinutes}
          handleSetReloadPointsAndInterventionsAndMinutes={handleSetReloadPointsAndInterventionsAndMinutes}
        />
      )}
      {(sessionData?.status === SESSION__PLENARY__STATUS__IN_REVIEW ||
        sessionData?.status === SESSION__PLENARY__STATUS__REVIEWED ||
        sessionData?.status === SESSION__PLENARY__STATUS__PUBLISHED) && (
        <DocumentAreaComponent
          documents={sessionData?.files}
          isCreatedAndScheduled={true}
          editableByStatus={editableByStatus}
          sessionData={sessionData}
          onReloadData={onReloadData}
        />
      )}
      {/*<div className={style.videoFloat}>
            <VideoPlayerComponent videoRef={videoRef}
                                  subtitles={sessionData?.video?.subtitles}
                                  videoUrl={sessionData?.video?.url} videoHeight={"auto"} />
        </div>*/}
    </>
  );
};

export default InReviewAndReviewDetailComponent;
