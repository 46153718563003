import React from "react";
import { List, ListItem, ListItemButton, Paper } from "@mui/material";
import TypographyComponent from "../../../../../../../../shared/typography/TypographyComponent";
import { useIntl } from "react-intl";
import { SESSION_AGENDA__TITLE } from "../../../../../../../../../translations/constans";
import { TEXT_VARIANT_H2 } from "../../../../../../../../shared/typography/constants";

const AgendaComponent = ({ agendaItems }) => {
  const intl = useIntl();
  return (
    <Paper
      elevation={4}
      style={{
        margin: "var(--spacing-xl)",
        padding: "var(--spacing-md)",
      }}
    >
      <TypographyComponent text={intl.formatMessage({ id: SESSION_AGENDA__TITLE })} variant={TEXT_VARIANT_H2} />
      <List component="nav">
        {!!agendaItems?.length > 0 &&
          agendaItems.map((item) => (
            <ListItem key={item.id} divider>
              {item.item_description}
            </ListItem>
          ))}
      </List>
    </Paper>
  );
};

export default AgendaComponent;
