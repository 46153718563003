import React, { useEffect, useState } from "react";
import { Avatar, Box, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  addParticipantsActionCreator,
  createParticipantActionCreator,
} from "../../../../../../../../../reducers/sessionDetal";
import ButtonComponent from "../../../../../../../../shared/button/ButtonComponent";
import {
  COMMON_CANCEL,
  COMMON_SAVE,
  PARTICIPANTS_CONFIG__ADD_PARTICIPANT,
  PARTICIPANTS_CONFIG__SELECT_PARTICIPANT,
  PARTICIPANTS_CONFIG__LOADING_PARTICIPANTS,
  PARTICIPANTS_CONFIG__NO_PARTICIPANTS,
} from "../../../../../../../../../translations/constans";
import { BUTTON } from "../../../../../../../../shared/button/buttonConstants";
import style from "./OrganizationParticipants.module.css";
import { useIntl } from "react-intl";
import TypographyComponent from "../../../../../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_H2, TEXT_VARIANT_P } from "../../../../../../../../shared/typography/constants";
import { getSessionPeople, saveNewParticipantService } from "../../../../../../../../../services/commonservices";
import LoadingWheelComponent from "../../../../../../../../common/loading/LoadingWheelComponent";
import clsx from "clsx";

const ParticipantList = ({ selectedParticipants, membersToAdd, onDeleteSelectComponent, onSelectParticipant }) => {
  const intl = useIntl();
  return membersToAdd !== null ? (
    membersToAdd.length > 0 ? (
      <TableContainer component={Paper} className={style.tableContainer}>
        <Table>
          <TableBody>
            {membersToAdd.map((member) => {
              const isSelected = !!selectedParticipants.find(
                (selectedParticipant) => selectedParticipant.id === member.id,
              );
              return (
                <TableRow
                  key={member.id}
                  className={clsx(isSelected && style.rowSelected, style.rowMember)}
                  onClick={() => {
                    isSelected
                      ? onDeleteSelectComponent(member.id)
                      : onSelectParticipant({ ...member, member_id: member.id });
                  }}
                >
                  <TableCell component="th" scope="row">
                    {member.name}
                  </TableCell>
                  <TableCell align="right">
                    <Avatar src={`data:image/jpeg;base64,${member.image}`} />
                  </TableCell>
                  <TableCell align="right">{member.title}</TableCell>
                  <TableCell align="right">{member.organization}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      intl.formatMessage({ id: PARTICIPANTS_CONFIG__NO_PARTICIPANTS })
    )
  ) : (
    <LoadingWheelComponent />
  );
};

const OrganizationMembersComponent = ({
  organizationMembers,
  onCloseForm,
  plenarySessionId,
  setInterventions,
  setAllChangesParticipants,
  handleSetReloadInterventionsAndMinutes,
  participantsAdded,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const [organizationMemberFiltered, setOrganizationMemberFiltered] = useState(organizationMembers);

  function removeMatchingParticipants() {
    const matchingParticipantsIds = participantsAdded.map((participant) => participant.member_id);
    return organizationMembers.filter((member) => !matchingParticipantsIds.includes(member.id));
  }
  useEffect(() => {
    organizationMembers !== null && setOrganizationMemberFiltered(removeMatchingParticipants());
  }, [organizationMembers]);
  const handleSelectParticipant = (participant) => {
    const newSelectedParticipants = [...selectedParticipants];
    newSelectedParticipants.push(participant);
    setSelectedParticipants(newSelectedParticipants);
  };
  const handleDeleteSelectParticipant = (participantId) => {
    const newSelectedParticipants = [...selectedParticipants].filter(
      (selectedParticipant) => selectedParticipant.id !== participantId,
    );
    setSelectedParticipants(newSelectedParticipants);
  };
  const callbackSaveNewParticipants = (response) => {
    dispatch(addParticipantsActionCreator(response.data));
    onCloseForm();
    handleSetReloadInterventionsAndMinutes();
  };
  const addNewPeopleToPlenary = (response) => {
    const peopleAddedInPlenary = response.data;
    const matchingOriginParticipants = peopleAddedInPlenary.map((participant) => participant.member_id);
    const membersSelectedToAdd = selectedParticipants.filter(
      (member) => !matchingOriginParticipants?.includes(member.id),
    );
    membersSelectedToAdd.length > 0
      ? membersSelectedToAdd.forEach((participant) => {
          saveNewParticipantService({ member_id: participant.id }, plenarySessionId, callbackSaveNewParticipants);
        })
      : onCloseForm();
  };
  const getPeopleInPlenary = () => {
    getSessionPeople(plenarySessionId, addNewPeopleToPlenary);
  };
  const handleAddParticipants = () => {
    setAllChangesParticipants(true);
    getPeopleInPlenary();
  };

  return (
    <Box sx={{ p: 2 }}>
      <TypographyComponent
        text={intl.formatMessage({ id: PARTICIPANTS_CONFIG__ADD_PARTICIPANT })}
        variant={TEXT_VARIANT_H2}
        style={{ marginBottom: "var(--spacing-md)" }}
      />
      <Box sx={{ mb: 2 }}>
        {selectedParticipants.length ? (
          selectedParticipants.map((participant) => (
            <Chip
              label={participant.name}
              onDelete={() => handleDeleteSelectParticipant(participant.id)}
              key={participant.id}
            />
          ))
        ) : (
          <>
            {organizationMembers !== null ? (
              <>
                {organizationMembers.length ? (
                  <TypographyComponent
                    text={intl.formatMessage({ id: PARTICIPANTS_CONFIG__SELECT_PARTICIPANT })}
                    variant={TEXT_VARIANT_P}
                    style={{ color: "red" }}
                  />
                ) : (
                  <TypographyComponent
                    text={intl.formatMessage({ id: PARTICIPANTS_CONFIG__NO_PARTICIPANTS })}
                    variant={TEXT_VARIANT_P}
                    style={{ color: "red" }}
                  />
                )}
              </>
            ) : (
              <TypographyComponent
                text={intl.formatMessage({ id: PARTICIPANTS_CONFIG__LOADING_PARTICIPANTS })}
                variant={TEXT_VARIANT_P}
                style={{ color: "red" }}
              />
            )}
          </>
        )}
      </Box>
      <ParticipantList
        selectedParticipants={selectedParticipants}
        membersToAdd={organizationMemberFiltered}
        onDeleteSelectComponent={handleDeleteSelectParticipant}
        onSelectParticipant={handleSelectParticipant}
      />
      <div className={style.buttonsContainer}>
        <ButtonComponent
          text={intl.formatMessage({ id: COMMON_CANCEL })}
          variant={BUTTON.VARIANT_DANGER_FILL}
          onClick={onCloseForm}
        />
        <ButtonComponent
          text={intl.formatMessage({ id: COMMON_SAVE })}
          variant={BUTTON.VARIANT_PRIMARY}
          onClick={handleAddParticipants}
        />
      </div>
    </Box>
  );
};

export default OrganizationMembersComponent;
