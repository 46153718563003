import { createTheme } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import Segoe_UI from "../../resources/fonts/Segoe_UI.ttf";
import { esES } from "@mui/x-data-grid";
import { esES as pickersEsES } from "@mui/x-date-pickers/locales";
import { esES as coreEsES } from "@mui/material/locale";
import logoHeader from "../../images/alaior_logo.png";

const SegoeUIMedium = {
  fontFamily: "SegoeUI",
  src: `local('Segoe_UI'),url(${Segoe_UI}) format('truetype')`,
};
export const DEFAULT_COLOURS = {
  primary: {
    light: "#e406131c",
    main: "#e30613",
    dark: "#b11c25",
  },
};

export const DEFAULT_THEME = createTheme({
  typography: {
    fontFamily: 'SegoeUI, "Roboto", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontFamily: SegoeUIMedium,
      fontSize: "1.75rem",
    },
    h2: {
      fontFamily: "SegoeUI",
      fontSize: "1.5rem",
    },
    h3: {
      fontFamily: "SegoeUI",
      fontSize: "1.2rem",
    },
    h4: {
      fontFamily: "SegoeUI",
      fontSize: "1.2rem",
    },
    h5: {
      fontFamily: "SegoeUI",
      fontSize: "1.1rem",
    },
    h6: {
      fontFamily: "SegoeUI",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    body1: {
      fontFamily: "SegoeUI",
      fontSize: "1rem",
    },
    body2: {
      fontFamily: "SegoeUI",
      fontSize: "0.9rem",
    },
    subtitle1: {
      fontFamily: "SegoeUI",
      fontSize: "0.7rem",
    },
    subtitle2: {
      fontFamily: "SegoeUI",
      fontSize: "0.65rem",
      fontWeight: 600,
    },
  },
  sizes: [4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768],
  textSizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72],
  palette: {
    primary: {
      light: grey[300],
      main: "#1d1d1b",
      dark: "black",
    },
    secondary: {
      light: "#e406131c",
      main: "#e30613",
      dark: "#b11c25",
    },
    disabled: {
      light: "#e7ebec",
      main: "#607d8b",
      dark: "#263238",
    },
    shadow: {
      light: "#d1d3d770",
      main: "rgba(0, 0, 0, 0.23)",
      dark: "rgba(0, 0, 0, 0.87)",
    },
    success: {
      light: green[300],
      main: green[700],
      dark: green[700],
    },
    warning: {
      light: "#e6b933",
      main: "#e0a800",
      dark: "#9c7500",
    },
    white: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#ffffff",
    },
    link: {
      light: "#3f51b5",
      main: "#3f51b5",
      dark: "#3f51b5",
    },
    blue: {
      light: "#eaf6ff",
      main: "#2196f3",
      dark: "#0069c5",
      disabled: "#0169c55e",
    },
    purple: {
      light: "#d1c4e9",
      main: "#673ab7",
      dark: "#4527a0",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [SegoeUIMedium],
      },
    },
    MuiTableRow: {
      root: {
        "&$hover:hover": {
          backgroundColor: "#D1D3D7",
        },
        "&:hover *": {
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid #b5acac66 !important",
        fontFamily: SegoeUIMedium,
      },
    },
    MuiMenuItem: {
      root: {
        lineHeight: 2.5,
      },
    },
  },
  esES,
  pickersEsES,
  coreEsES,
});

export const DEFAULT_CONFIG = {
  headerData: {
    logo: {
      src: logoHeader,
      alt: "",
      link: "/",
    },
  },
  footerData: {
    logo: {
      src: "",
      alt: "",
      link: "",
    },
    copyrightName: "",
    privacyPolicy: "",
    useTerms: "",
  },
  helpUrl: "",
  favicon: "/static/favicon.ico",
};
