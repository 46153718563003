import React from "react";
import TimeInput from "../../../../../../shared/timeInput/TimeInput";
import { SESSION_MINUTES_FROM, SESSION_MINUTES_TO } from "../../../../../../../utils/constants";
import { useIntl } from "react-intl";
import { MINUTES_MANAGER__END, MINUTES_MANAGER__START } from "../../../../../../../translations/constans";
import style from "./MinutesManager.module.css";

const MinutesManagerComponent = ({
  isCreating,
  timingFrom,
  timingTo,
  videoRef,
  handleSave,
  onTimeChange,
  editableByStatus,
  buttonDisabled,
  statusReviewedPublished,
}) => {
  const intl = useIntl();
  const handleTimeChange = (newMilliseconds, timer) => {
    if (videoRef.current) {
      videoRef.current.seekTo(newMilliseconds / 1000, "seconds");
      onTimeChange(newMilliseconds, timer);
    }
  };

  const handleGetFixHourFromPlayer = (timer) => {
    const newMilliseconds = videoRef.current.getCurrentTime();
    handleSave(newMilliseconds, timer);
    return newMilliseconds;
  };

  return (
    <div className={style.timeInput}>
      <div>
        <TimeInput
          initialMilliseconds={timingFrom}
          handleSave={(time) => handleSave(time, SESSION_MINUTES_FROM)}
          timer={SESSION_MINUTES_FROM}
          handleTimeChange={(time) => handleTimeChange(time, SESSION_MINUTES_FROM)}
          onGetTimePlayer={() => handleGetFixHourFromPlayer(SESSION_MINUTES_FROM)}
          buttonText={intl.formatMessage({ id: MINUTES_MANAGER__START })}
          isCreating={isCreating}
          editableByStatus={editableByStatus}
          buttonDisabled={buttonDisabled}
          statusReviewedPublished={statusReviewedPublished}
        />
      </div>
      <div>
        <TimeInput
          initialMilliseconds={timingTo}
          handleSave={(time) => handleSave(time, SESSION_MINUTES_TO)}
          timer={SESSION_MINUTES_TO}
          handleTimeChange={(time) => handleTimeChange(time, SESSION_MINUTES_TO)}
          onGetTimePlayer={() => handleGetFixHourFromPlayer(SESSION_MINUTES_TO)}
          buttonText={intl.formatMessage({ id: MINUTES_MANAGER__END })}
          isCreating={isCreating}
          editableByStatus={editableByStatus}
          buttonDisabled={buttonDisabled}
          statusReviewedPublished={statusReviewedPublished}
        />
      </div>
    </div>
  );
};
export default MinutesManagerComponent;
