import React, { useEffect, useState } from "react";
import ParticipantTable from "./components/participantTable/ParticipantTable";
import {Box, Button, Modal, Paper} from "@mui/material";
import CreateOrEditParticipantModalComponent from "./components/createOrEditParticipantModalComponent/CreateOrEditParticipantModalComponent";
import {
  getRoomsService,
  saveNewParticipantService,
  saveParticipantService,
} from "../../../../../services/commonservices";
import { useDispatch, useSelector } from "react-redux";
import {deleteParticipantOfPlenaryActionCreator} from "../../../../../reducers/sessionDetal";
import TypographyComponent from "../../../../shared/typography/TypographyComponent";
import { useIntl } from "react-intl";
import style from "./ParticipantsConfiguration.module.css";
import { TEXT_VARIANT_H2 } from "../../../../shared/typography/constants";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  COMMON__ACCEPT, COMMON__DELETE,
  COMMON_CANCEL,
  COMMON_SAVE, DELETE_PARTICIPANTS, MESSAGE_DELETE_PARTICIPANTS, MESSAGE_DELETE_PARTICIPANTS_WITH_CHANGES,
  NOT_DATA,
  PARTICIPANTS_CONFIG__TITLE,
  PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__ASSIGN_BUTTON,
  PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__CREATE_PARTICIPANT__BUTTON, SAVE_AND_CLOSE,
} from "../../../../../translations/constans";
import ButtonComponent from "../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../shared/button/buttonConstants";
import { CheckCircleOutline } from "@mui/icons-material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Tooltip from "@mui/material/Tooltip";
import ModalComponent from "../../../../shared/modal/ModalComponent";

const ParticipantsConfigurationComponent = ({
  plenarySessionId,
  roomId,
  onReloadData,
  editableByStatus,
  handleSetReloadInterventionsAndMinutes = () => {},
  handleSetReloadPointsAndInterventionsAndMinutes = () => {},
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [openedModal, setOpenedModal] = useState({
    open: false,
    isNew: false,
    participant: null,
  });
  const [room, setRoom] = useState(null);

  const participants = useSelector((state) => state.sessionDetail.participants);

  const [allChangesParticipants, setAllChangesParticipants] = useState(true);
  const [deleteParticipantModal, setDeleteParticipantModal] = useState(false);
  const [participantToDelete, setParticipantToDelete] = useState(null);
  const [isDeleteCompleted, setIsDeleteCompleted] = useState(false);
  const handleSetRooms = (response) => {
    setRoom(response.data);
  };
  useEffect(() => {
    if (roomId) {
      getRoomsService(roomId, handleSetRooms);
    }
  }, [roomId]);
  const handleOpenedModal = (open, isNew, participant) => {
    setOpenedModal({
      open,
      isNew,
      participant,
    });
  };
  const handleDeleteParticipant = (participantId) => {
    setParticipantToDelete(participantId);
    setDeleteParticipantModal(true);
  };

  const confirmDeleteParticipant = () => {
    dispatch(deleteParticipantOfPlenaryActionCreator(participantToDelete));
    setAllChangesParticipants(false);
    setIsDeleteCompleted(true);
    setDeleteParticipantModal(false);
    setParticipantToDelete(null);
  };
  useEffect(() => {
    handleSaveConfiguration();
  }, [isDeleteCompleted]);
  const saveChangesAndCloseModal = () => {
    setDeleteParticipantModal(false);
    handleSaveConfiguration();
  }
  const handleSaveConfiguration = () => {
    saveParticipantService(participants, plenarySessionId, handleSetReloadPointsAndInterventionsAndMinutes);
    setAllChangesParticipants(true);
  };
  const activatedSaveButton = () => {
    setAllChangesParticipants(false);
  };

  const handleCloseDeleteParticipantModal = () => {
    setDeleteParticipantModal(false);
  };
  return (
    <Paper
      elevation={4}
      style={{
        margin: "var(--spacing-xl)",
        padding: "var(--spacing-xl)",
      }}
    >
      <TypographyComponent text={intl.formatMessage({ id: PARTICIPANTS_CONFIG__TITLE })} variant={TEXT_VARIANT_H2} />
      <div className={style.participantsButtonsContainer}>
        <div>
          <ButtonComponent
            disabled={!editableByStatus}
            variant={editableByStatus ? BUTTON.VARIANT_SECONDARY : BUTTON.VARIANT_DISABLE}
            icon={<PostAddIcon />}
            size={BUTTON.SIZE__FIT_CONTENT}
            text={intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__ASSIGN_BUTTON })}
            onClick={() => handleOpenedModal(true, false, null)}
          />
        </div>
        <div className={style.buttonAddParticipantContainer}>
          <ButtonComponent
            disabled={!editableByStatus}
            variant={editableByStatus ? BUTTON.VARIANT_SECONDARY : BUTTON.VARIANT_DISABLE}
            icon={<AddCircleOutlineIcon />}
            size={BUTTON.SIZE__FIT_CONTENT}
            text={intl.formatMessage({
              id: PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__CREATE_PARTICIPANT__BUTTON,
            })}
            onClick={() => handleOpenedModal(true, true, null)}
          />
        </div>
      </div>
      {openedModal.open && (
        <CreateOrEditParticipantModalComponent
          openedModal={openedModal}
          participants={participants}
          plenarySessionId={plenarySessionId}
          seats={room?.seats}
          onClose={handleOpenedModal}
          onReloadData={onReloadData}
          handleSetReloadInterventionsAndMinutes={handleSetReloadInterventionsAndMinutes}
          setAllChangesParticipants={setAllChangesParticipants}
        />
      )}
          <Modal
              open={deleteParticipantModal}
              onClose={handleCloseDeleteParticipantModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
            {(editableByStatus && !allChangesParticipants) ? (
                <ModalComponent
                  handleClose={handleCloseDeleteParticipantModal}
                  title={null}
                  body={
                    <TypographyComponent
                        className={style.messageModal}
                        text={intl.formatMessage({ id: MESSAGE_DELETE_PARTICIPANTS_WITH_CHANGES })}
                    />
                  }
                  buttonAcceptText={intl.formatMessage({ id: COMMON_SAVE })}
                  buttonCancelText={intl.formatMessage({ id: COMMON_CANCEL })}
                  handleSave={saveChangesAndCloseModal}
                  onCancel={handleCloseDeleteParticipantModal} // Cierra la modal al cancelar
                />
            ) : (
                <ModalComponent
                  handleClose={handleCloseDeleteParticipantModal}
                  title={intl.formatMessage({ id: DELETE_PARTICIPANTS })}
                  body={
                    <TypographyComponent
                        className={style.messageModal}
                        text={intl.formatMessage({ id: MESSAGE_DELETE_PARTICIPANTS })}
                    />
                  }
                  buttonAcceptText={intl.formatMessage({ id: COMMON__DELETE })}
                  buttonCancelText={intl.formatMessage({ id: COMMON_CANCEL })}
                  handleSave={confirmDeleteParticipant} // Elimina el participante al aceptar
                  onCancel={handleCloseDeleteParticipantModal} // Cierra la modal al cancelar
                />
            )}

          </Modal>
     <ParticipantTable
  seats={room?.seats}
  rows={participants}
  plenarySessionId={plenarySessionId}
  onDeleteParticipant={handleDeleteParticipant}
  onEditParticipant={(participant) => handleOpenedModal(true, false, participant)}
  editableByStatus={editableByStatus}
  handleSaveConfiguration={activatedSaveButton}
/>

      <Tooltip title={allChangesParticipants ? intl.formatMessage({ id: NOT_DATA }) : ""}>
        <div className={style.saveButtonContainer}>
          <ButtonComponent
            icon={<CheckCircleOutline />}
            size={BUTTON.SIZE__FIT_CONTENT}
            disabled={!editableByStatus || allChangesParticipants}
            variant={editableByStatus && !allChangesParticipants ? BUTTON.VARIANT_PRIMARY : BUTTON.VARIANT_DISABLE}
            text={intl.formatMessage({ id: COMMON_SAVE })}
            onClick={handleSaveConfiguration}
          />
        </div>
      </Tooltip>
    </Paper>
  );
};

export default ParticipantsConfigurationComponent;
