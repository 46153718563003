import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import styles from "./LoadingComponentStyles";
import logoCinfo from "../../../images/logo_cinfo.svg";
import { COMMON__LOADING_DESCRIPTION } from "../../../translations/constans";

const useStyles = makeStyles((theme) => styles(theme));

const LoadingComponent = ({ description, open }) => {
  const classes = useStyles();
  return (
    <div className={!open ? `${classes.disable} ${classes.root} pageContainer` : `${classes.root} pageContainer`}>
      <div className={classes.imageContainer}>
        <img src={logoCinfo} className="App-logo" alt="logo" />
        {description && (
          <p className={classes.description}>
            <FormattedMessage id={COMMON__LOADING_DESCRIPTION} />
          </p>
        )}
      </div>
    </div>
  );
};

LoadingComponent.propTypes = {
  description: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

export default LoadingComponent;
