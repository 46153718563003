import React, { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";

import ErrorComponent from "../ErrorComponent";
import { COMMON__ERROR_403, COMMON__LOADING_DESCRIPTION } from "../../../translations/constans";
import { getAllowedService } from "../../../services/commonservices";

const withAuthorization = (roles) => (Component) => {
  return (props) => {
    // eslint-disable-next-line react/function-component-definition
    const { keycloak, initialized } = useKeycloak();
    const [authorized, setAuthorized] = useState(null);

    const allowedCallback = () => {
      setAuthorized(true);
    }

    const allowedErrorCallback = () => {
      setAuthorized(false);
    }
    if (keycloak?.authenticated) {
      getAllowedService(allowedCallback, allowedErrorCallback);
    }

    if (!!initialized && !!keycloak && !keycloak.authenticated) {
      keycloak?.login();
      return <ErrorComponent error={COMMON__LOADING_DESCRIPTION} />;
    } else if (!!keycloak && !initialized) {
      return <ErrorComponent error={COMMON__LOADING_DESCRIPTION} />;
    }
    if (authorized === null) {
      return <ErrorComponent error={COMMON__LOADING_DESCRIPTION} />;
    } else if (authorized) {
      return <Component {...props} />;
    } else {
      keycloak?.logout();
      return <ErrorComponent error={COMMON__ERROR_403} />;
    }
  };
}

export default withAuthorization;
