import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import {
  SESSION_VIDEO_PLAYER__CONTROLS_LIST,
  SESSION_VIDEO_PLAYER__TRUCKS_TYPE_SUBTITLES,
} from "../../../../../../../utils/constants";
import style from "./VideoPlayerComponent.module.css";

const VideoPlayerComponent = ({ subtitles, videoRef, videoUrl, videoHeight = "auto" }) => {
  const defaultLanguage = useSelector((state) => state.common.language);

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 200, y: window.innerHeight / 2 });
  const [videoPosition, setVideoPosition] = useState("relative");
  const [videoWidth, setVideoWidth] = useState("100%");
  const [currentTime, setCurrentTime] = useState(0);
  const [initialDuration, setInitialDuration] = useState(0);
  const ref = useRef(null);

  const validateReturnToOrigin = (event) => {
    if (event.pageY > 750 && event.pageY < 1200) {
      setVideoPosition("relative");
      setVideoWidth("100%");
    }
  };

  const handleMouseDown = (event) => {
    setVideoPosition("absolute");
    setVideoWidth("400px");
    setIsDragging(true);
    const startX = event.pageX - position.x;
    const startY = event.pageY - position.y;

    const handleMouseMove = (event) => {
      const newPosition = {
        x: event.pageX - startX,
        y: event.pageY - startY,
      };
      setPosition(newPosition);
    };

    const handleMouseUp = (event) => {
      setIsDragging(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      validateReturnToOrigin(event);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);
    setInitialDuration(videoRef?.current?.getDuration());
  };

  const handleDuration = (duration) => {
    setInitialDuration(duration);
  };

  useEffect(() => {
    const handleScroll = () => {
      setPosition((prevPosition) => ({
        x: prevPosition.x,
        y: window.scrollY + window.innerHeight / 2,
      }));
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const formatTime = (seconds) => {
    const hh = Math.floor(seconds / 3600);
    const mm = Math.floor((seconds % 3600) / 60);
    const ss = Math.floor(seconds % 60);
    return {
      hh: String(hh).padStart(2, '0'),
      mm: String(mm).padStart(2, '0'),
      ss: String(ss).padStart(2, '0')
    };
  };

  const currentTimeFormatted = formatTime(currentTime);
  const initialDurationFormatted = formatTime(initialDuration);

  return (
    <div
      ref={ref}
      style={{
        position: videoPosition,
        left: videoPosition === "relative" ? "initial" : position.x,
        top: videoPosition === "relative" ? "initial" : position.y,
        cursor: isDragging ? "grabbing" : "grab",
        width: videoWidth,
        zIndex: "99",
        height: "auto",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 0.5rem",
      }}
      onMouseDown={handleMouseDown}
    >
      {videoUrl && (
        <>
          <ReactPlayer
            className="react-player"
            config={{
              file: {
                attributes: {
                  controlsList: SESSION_VIDEO_PLAYER__CONTROLS_LIST,
                },
                tracks: [
                  {
                    kind: SESSION_VIDEO_PLAYER__TRUCKS_TYPE_SUBTITLES,
                    src: subtitles,
                    srcLang: defaultLanguage,
                    default: true,
                  },
                ],
              },
            }}
            subtitles={
              !!subtitles && subtitles.length
                ? {
                    kind: "subtitles",
                    src: subtitles[0]?.url,
                    srcLang: subtitles[0]?.lang,
                    default: true,
                  }
                : {}
            }
            controls={true}
            height={videoHeight}
            ref={videoRef}
            url={videoUrl}
            width="100%"
            onProgress={handleProgress}
            onDuration={handleDuration}
          />
          <div className={style.timeOverlay}>
            <div className={style.timeContainer}>
              <span className={style.timeSegment}>{currentTimeFormatted.hh}</span>
              <span className={style.separator}>:</span>
              <span className={style.timeSegment}>{currentTimeFormatted.mm}</span>
              <span className={style.separator}>:</span>
              <span className={style.timeSegment}>{currentTimeFormatted.ss}</span>
            </div>
            <span className={style.separator}>/</span>
            <div className={style.timeContainer}>
              <span className={style.timeSegment}>{initialDurationFormatted.hh}</span>
              <span className={style.separator}>:</span>
              <span className={style.timeSegment}>{initialDurationFormatted.mm}</span>
              <span className={style.separator}>:</span>
              <span className={style.timeSegment}>{initialDurationFormatted.ss}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VideoPlayerComponent;
