import React, { useState } from "react";
import DocumentManagerComponent from "../documentManagerComponent/DocumentManagerComponent";

const DocumentAreaComponent = ({ documents, isCreatedAndScheduled, onReloadData, editableByStatus, sessionData }) => {
  return (
    <DocumentManagerComponent
      onReloadData={onReloadData}
      isCreatedAndScheduled={isCreatedAndScheduled}
      documents={documents}
      editableByStatus={editableByStatus}
    />
  );
};
export default DocumentAreaComponent;
