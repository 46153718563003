import { useIntl } from "react-intl";

const useCustomIntl = () => {
  const intl = useIntl();
  const formatMessage = (messageId, values) => {
    return intl.formatMessage({ id: messageId }, values);
  };
  function formatString(str) {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w]+/g, "_");
  }
  return { formatMessage, formatString };
};
export default useCustomIntl;
