import React from "react";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import VotingModalComponent from "../votingModalComponent/VotingModalComponent";
import {
  SESSION__PLENARY__STATUS__PUBLISHED,
  SESSION__PLENARY__STATUS__REVIEWED,
} from "../../../../../../../../../../../utils/constants";

const ParticipantsListComponent = ({
  activeAgendaItemId,
  participants,
  plenarySessionId,
  plenaryTitle,
  onUpdateSelectParticipant,
}) => {
  return (
    <List component="nav">
      {participants.map((participant) => (
        <ListItemButton
          selected={participant.active}
          onClick={() => onUpdateSelectParticipant(participant.id)}
          key={participant.id}
          divider
          sx={{
            "&.Mui-selected": {
              backgroundColor: "rgba(81, 255, 0, 0.2)",
              color: "rgb(0,125,0)",
              fontWeight: "bold",
              ":hover": {
                backgroundColor: "rgb(220, 220, 220)",
                color: "black",
                fontWeight: "normal",
              },
            },
            ":hover": {
              backgroundColor: "rgb(220, 220, 220)",
            },
          }}
        >
          <ListItemText primary={participant.name} />
          {participant.type === "voting" && (
            <VotingModalComponent
              activeAgendaItemId={activeAgendaItemId}
              activeAgendaItemTitle={plenaryTitle}
              plenarySessionId={plenarySessionId}
            />
          )}
        </ListItemButton>
      ))}
    </List>
  );
};
export default ParticipantsListComponent;
