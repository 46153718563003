import React from "react";
import SessionInformationComponent from "../sessionInformationComponent/SessionInformationComponent";
import VideoAreaComponent from "./components/videoAreaComponent/VideoAreaComponent";
import OrderAreaComponent from "./components/orderAreaComponent/OrderAreaComponent";
import DocumentAreaComponent from "../documentAreacComponent/DocumentAreaComponent";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import style from "./InDirectComponent.module.css";
import {
  SESSION__PLENARY__STATUS__FINISHED,
  SESSION__PLENARY__STATUS__STARTED,
  SESSION__PLENARY__STATUS__STARTING,
} from "../../../../../utils/constants";
import { useSelector } from "react-redux";
import { Paper } from "@mui/material";

const InDirectComponent = ({ isVideoAreaComponent, plenarySessionId, videoRef, onReloadData, editableByStatus }) => {
  const sessionData = useSelector((state) => state.sessionDetail.sessionDetailData);

  const showVideoPlayer =
    sessionData?.status !== SESSION__PLENARY__STATUS__STARTING ||
    (sessionData?.status === SESSION__PLENARY__STATUS__FINISHED && !!sessionData?.video?.url);

  const urlVideoToPlay = () => {
    return sessionData?.status === SESSION__PLENARY__STATUS__STARTED
      ? sessionData?.video?.live_url
      : sessionData?.status === SESSION__PLENARY__STATUS__FINISHED && sessionData?.video?.url;
  };

  return (
    <div className={style.inDirectContainer}>
      <SessionInformationComponent
        plenarySessionId={plenarySessionId}
        onReloadData={onReloadData}
        editableByStatus={editableByStatus}
      />
      <VideoAreaComponent
        isVideoAreaComponent={isVideoAreaComponent}
        plenarySessionId={plenarySessionId}
        url={urlVideoToPlay()}
        videoRef={videoRef}
        onReloadData={onReloadData}
        editableByStatus={editableByStatus}
        showVideoPlayer={showVideoPlayer}
      />
      <OrderAreaComponent
        plenaryTitle={sessionData?.title}
        plenarySessionId={plenarySessionId}
        editableByStatus={editableByStatus}
        onReloadData={onReloadData}
      />
      <DocumentAreaComponent
        documents={sessionData?.files}
        isCreatedAndScheduled={true}
        editableByStatus={editableByStatus}
        onReloadData={onReloadData}
      />
    </div>
  );
};

export default InDirectComponent;
