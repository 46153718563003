import React, { useEffect, useRef } from "react";
import withAuthorization from "../../common/auth/withAuthorization";
import {
  ROLES_ALL,
  SESSION__PLENARY__STATUS__CREATED,
  SESSION__PLENARY__STATUS__ERROR,
  SESSION__PLENARY__STATUS__FINISHED,
  SESSION__PLENARY__STATUS__IN_REVIEW,
  SESSION__PLENARY__STATUS__PUBLISHED,
  SESSION__PLENARY__STATUS__REVIEWED,
  SESSION__PLENARY__STATUS__SCHEDULED,
  SESSION__PLENARY__STATUS__STARTED,
  SESSION__PLENARY__STATUS__STARTING,
} from "../../../utils/constants";
import { getPlenarySessionFiles, getSessionInfo } from "../../../services/commonservices";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateSessionDetailDataActionCreator } from "../../../reducers/sessionDetal";
import InReviewAndReviewDetailComponent from "./components/inReviewAndReviewDetailComponent/InReviewAndReviewDetailComponent";
import InCreatedAndScheduled from "./components/inCreatedAndScheduled/InCreatedAndScheduled";
import InDirectComponent from "./components/inDirectComponent/InDirectComponent";
import InErrorComponent from "./components/inErrorComponent/InErrorComponent";
import SessionInformationComponent from "./components/sessionInformationComponent/SessionInformationComponent";

const canEditByStatus = (statusValue) => {
  const statusEditObject = {
    [SESSION__PLENARY__STATUS__CREATED]: true,
    [SESSION__PLENARY__STATUS__SCHEDULED]: true,
    [SESSION__PLENARY__STATUS__FINISHED]: false,
    [SESSION__PLENARY__STATUS__ERROR]: false,
    [SESSION__PLENARY__STATUS__STARTED]: true,
    [SESSION__PLENARY__STATUS__STARTING]: true,
    [SESSION__PLENARY__STATUS__IN_REVIEW]: true,
    [SESSION__PLENARY__STATUS__REVIEWED]: false,
    [SESSION__PLENARY__STATUS__PUBLISHED]: false,
  };
  return statusEditObject[statusValue];
};

const SessionDetailPage = () => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  let { id } = useParams();
  const sessionData = useSelector((state) => state.sessionDetail.sessionDetailData);

  const handleSessionDataAndFiles = (files, data) => {
    data.files = files;
    dispatch(updateSessionDetailDataActionCreator(data));
  }

  const handleSessionData = (data) => {
    getPlenarySessionFiles(id, (response) => handleSessionDataAndFiles(response.data, data));
  };

  const getDetailPageData = () => {
    getSessionInfo(id, (response) => handleSessionData(response.data));
  };

  useEffect(() => {
    getDetailPageData();
  }, []);

  const handleChangeOrder = (newAgendas) => {
    const newSessionData = { ...sessionData };
    newSessionData.agenda = newAgendas;
    handleSessionData(newSessionData);
  };

  switch (sessionData?.status) {
    case SESSION__PLENARY__STATUS__SCHEDULED:
    case SESSION__PLENARY__STATUS__CREATED:
      return (
        <InCreatedAndScheduled
          plenarySessionId={id}
          sessionData={sessionData}
          videoRef={videoRef}
          onChangeOrder={handleChangeOrder}
          onReloadData={getDetailPageData}
          editableByStatus={() => canEditByStatus(sessionData.status)}
        />
      );
    case SESSION__PLENARY__STATUS__ERROR:
      return (
        <div>
          <SessionInformationComponent
            plenarySessionId={id}
            sessionData={sessionData}
            onReloadData={getDetailPageData}
            editableByStatus={() => canEditByStatus(sessionData.status)}
          />
          <InErrorComponent />
        </div>
      );
    case SESSION__PLENARY__STATUS__STARTING:
    case SESSION__PLENARY__STATUS__STARTED:
    case SESSION__PLENARY__STATUS__FINISHED:
      return (
        <InDirectComponent
          plenarySessionId={id}
          videoRef={videoRef}
          onReloadData={getDetailPageData}
          isVideoAreaComponent={true}
          editableByStatus={() => canEditByStatus(sessionData.status)}
          onChangeOrder={handleChangeOrder}
        />
      );
    case SESSION__PLENARY__STATUS__IN_REVIEW:
    case SESSION__PLENARY__STATUS__REVIEWED:
    case SESSION__PLENARY__STATUS__PUBLISHED:
      return (
        <InReviewAndReviewDetailComponent
          plenarySessionId={id}
          sessionData={sessionData}
          videoRef={videoRef}
          onReloadData={getDetailPageData}
          isVideoAreaComponent={true}
          editableByStatus={() => canEditByStatus(sessionData.status)}
        />
      );
    default:
      return <div />;
  }
};

export default withAuthorization(ROLES_ALL)(SessionDetailPage);
