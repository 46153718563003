export const DEFAULT_TEXT = "Texto de ejemplo";
export const TEXT_VARIANT_H1 = "h1";
export const TEXT_VARIANT_H2 = "h2";
export const TEXT_VARIANT_H3 = "h3";
export const TEXT_VARIANT_H4 = "h4";
export const TEXT_VARIANT_P = "p";
export const TEXT_VARIANT_SPAN = "span";
export const TEXT_VARIANT_STRONG = "strong";
export const TEXT_VARIANT_SMALL = "small";
export const STATUS_PAGE = "Estado";
export const HOME_PAGE = "Plataforma Mileva";
