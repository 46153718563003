import React, { useEffect, useState } from "react";
import {
  getIsSeatsConfiguredService,
  updateAutoMixingService,
} from "../../../../../../../../../services/commonservices";
import AutomaticProductionOptions from "../AutomaticProductionOptionsComponent";

const AutomaticProduction = ({ plenarySessionId, sessionData, editableByStatus }) => {
  const [autoSelectionDisabled, setAutoSelectionDisabled] = useState(false);
  const [automaticProductionOptions, setAutomaticProductionOptions] = useState({});

  useEffect(() => {
    if (sessionData) {
      let options = {
        auto_mixing: sessionData?.auto_mixing?.auto_mixing,
        auto_participant_selection: sessionData?.auto_mixing?.auto_participant_selection,
        auto_titler: sessionData?.auto_mixing?.auto_titler,
      };
      setAutomaticProductionOptions(options);
    }
  }, [sessionData?.auto_mixing]);

  const handleAutomaticProductionOption = (options) => {
    if (plenarySessionId) {
      updateAutoMixingService(plenarySessionId, options);
    }
  };

  const handleAutoSelectionDisabled = (response) => {
    setAutoSelectionDisabled(!response.data);
  };
  useEffect(() => {
    if (plenarySessionId) {
      getIsSeatsConfiguredService(plenarySessionId, handleAutoSelectionDisabled);
    }
  }, [plenarySessionId]);

  return (
    <AutomaticProductionOptions
      options={automaticProductionOptions}
      setOptions={handleAutomaticProductionOption}
      autoSelectionDisabled={autoSelectionDisabled}
    />
  );
};

export default AutomaticProduction;
