import React from "react";
import { updateSessionStatus } from "../../../../../../../services/commonservices";
import { hasRoles } from "../../../../../../../utils/keycloak";
import {
  ROLE_TIIVII_SUPER_USER,
  ROLE_TIIVIIPLEN_ADMIN_USER,
  ROLE_TIIVIIPLEN_USER,
  SESSION__PLENARY__STATUS__IN_REVIEW,
  SESSION__PLENARY__STATUS__PUBLISHED,
  SESSION__PLENARY__STATUS__REVIEWED,
  SESSION__PLENARY__STATUS__FINISHED,
} from "../../../../../../../utils/constants";
import { useSelector } from "react-redux";
import {
  PLENARY__SESSIONS__STATUS__CHANGE_NOT_POSSIBLE,
  PLENARY__SESSIONS__STATUS__PUBLISH,
  PLENARY__SESSIONS__STATUS__TITLE,
  PLENARY__SESSIONS__STATUS__MARK_AS_IN_REVIEW,
  PLENARY__SESSIONS__STATUS__MARK_AS_REVIEWED,
  PLENARY__SESSIONS__STATUS__RETURN_TO_REVIEWED,
} from "../../../../../../../translations/constans";
import { useIntl } from "react-intl";
import { BUTTON } from "../../../../../../shared/button/buttonConstants";
import ButtonComponent from "../../../../../../shared/button/ButtonComponent";
import style from "./StatusChange.module.css";

const StatusChangeComponent = ({ currentStatus, plenarySessionId, onReloadData }) => {
  const intl = useIntl();
  const handleChangeStatus = async (newStatus) => {
    updateSessionStatus(plenarySessionId, newStatus, onReloadData);
  };
  const keycloak = useSelector((state) => state.common.keycloak);

  const isCorrectRoleToReview = hasRoles(keycloak, [
    ROLE_TIIVII_SUPER_USER,
    ROLE_TIIVIIPLEN_ADMIN_USER,
    ROLE_TIIVIIPLEN_USER,
  ]);
  const isCorrectRoleToInReviewAndPublished = hasRoles(keycloak, [
    ROLE_TIIVII_SUPER_USER,
    ROLE_TIIVIIPLEN_ADMIN_USER,
    ROLE_TIIVIIPLEN_USER,
  ]);

  const optionChangeStatus = {
    [SESSION__PLENARY__STATUS__FINISHED]: [
      {
        button_text: PLENARY__SESSIONS__STATUS__MARK_AS_IN_REVIEW,
        button_action: SESSION__PLENARY__STATUS__IN_REVIEW,
      },
    ],
    [SESSION__PLENARY__STATUS__IN_REVIEW]: [
      {
        button_text: PLENARY__SESSIONS__STATUS__MARK_AS_REVIEWED,
        button_action: SESSION__PLENARY__STATUS__REVIEWED,
      },
    ],
    [SESSION__PLENARY__STATUS__REVIEWED]: [
      {
        button_text: PLENARY__SESSIONS__STATUS__PUBLISH,
        button_action: SESSION__PLENARY__STATUS__PUBLISHED,
      },
      {
        button_text: PLENARY__SESSIONS__STATUS__RETURN_TO_REVIEWED,
        button_action: SESSION__PLENARY__STATUS__IN_REVIEW,
      },
    ],
    [SESSION__PLENARY__STATUS__PUBLISHED]: [
      {
        button_text: PLENARY__SESSIONS__STATUS__RETURN_TO_REVIEWED,
        button_action: SESSION__PLENARY__STATUS__REVIEWED,
      },
    ],
  };

  return (
    <div>
      <div className={style.statusChange}>
        <h3 className={style.statusChangeText}>
          <strong>{intl.formatMessage({ id: PLENARY__SESSIONS__STATUS__TITLE })}</strong>
        </h3>
        {!(currentStatus === SESSION__PLENARY__STATUS__FINISHED && isCorrectRoleToReview) &&
        !(currentStatus === SESSION__PLENARY__STATUS__IN_REVIEW && isCorrectRoleToReview) &&
        !(
          (currentStatus === SESSION__PLENARY__STATUS__REVIEWED ||
            currentStatus === SESSION__PLENARY__STATUS__PUBLISHED) &&
          isCorrectRoleToInReviewAndPublished
        ) ? (
          <h4 className={style.statusChangeNotPossible}>
            {intl.formatMessage({ id: PLENARY__SESSIONS__STATUS__CHANGE_NOT_POSSIBLE })}
          </h4>
        ) : (
          <div className={style.statusChangeButton}>
            {optionChangeStatus[currentStatus]?.map((status, index) => (
              <div key={status?.button_action}>
                <ButtonComponent
                  variant={index === 0 ? BUTTON.VARIANT_PRIMARY : BUTTON.VARIANT_SECONDARY}
                  onClick={() => handleChangeStatus(status?.button_action)}
                  text={intl.formatMessage({ id: status?.button_text })}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusChangeComponent;
