import Keycloak from "keycloak-js";
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from "./constants";

const keycloakConfig = {
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

const keycloak = new Keycloak(keycloakConfig);

export const hasRole = (kc, role) => (kc ? kc.hasRealmRole(role) || kc.hasResourceRole(role) : false);

export const hasRoles = (kc, roles) => {
  return kc ? roles.some((r) => kc.hasRealmRole(r) || kc.hasResourceRole(r)) : false;
};

export default keycloak;
