import {
  PLENARY__SESSIONS__END_DATE,
  PLENARY__SESSIONS__INIT_DATE,
  PLENARY__SESSIONS__STATUS__STATUS,
  PLENARY__SESSIONS__SUBTITLE,
  PLENARY__SESSIONS__TITLE,
  PLENARY_LIST__FILTER_SELECT_PLACE,
  PLENARY_STATUS__CREATED,
  PLENARY_STATUS__ERROR,
  PLENARY_STATUS__FINISHED,
  PLENARY_STATUS__IN_REVIEW,
  PLENARY_STATUS__PUBLISHED,
  PLENARY_STATUS__REVIEWED,
  PLENARY_STATUS__SCHEDULED,
  PLENARY_STATUS__STARTED,
  PLENARY_STATUS__STARTING,
} from "../../../translations/constans";
import {
  SESSION__PLENARY__STATUS__CREATED,
  SESSION__PLENARY__STATUS__ERROR,
  SESSION__PLENARY__STATUS__FINISHED,
  SESSION__PLENARY__STATUS__IN_REVIEW,
  SESSION__PLENARY__STATUS__PUBLISHED,
  SESSION__PLENARY__STATUS__REVIEWED,
  SESSION__PLENARY__STATUS__SCHEDULED,
  SESSION__PLENARY__STATUS__STARTED,
  SESSION__PLENARY__STATUS__STARTING,
} from "../../../utils/constants";

export const plenaryListColumns = (intl) => [
  {
    headerName: intl.formatMessage({ id: PLENARY__SESSIONS__TITLE }),
    field: "title",
  },
  {
    headerName: intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_PLACE }),
    field: "plenary_room_name",
  },
  {
    headerName: intl.formatMessage({ id: PLENARY__SESSIONS__INIT_DATE }),
    field: "init_date",
  },
  {
    headerName: intl.formatMessage({ id: PLENARY__SESSIONS__END_DATE }),
    field: "end_date",
  },
  {
    headerName: intl.formatMessage({ id: PLENARY__SESSIONS__STATUS__STATUS }),
    field: "status",
  },
];

export const plenaryStatusTranslation = (intl, statusValue) => {
  const PLENARY_STATUS_TRANSLATED = {
    [SESSION__PLENARY__STATUS__IN_REVIEW]: intl.formatMessage({ id: PLENARY_STATUS__IN_REVIEW }),
    [SESSION__PLENARY__STATUS__REVIEWED]: intl.formatMessage({ id: PLENARY_STATUS__REVIEWED }),
    [SESSION__PLENARY__STATUS__PUBLISHED]: intl.formatMessage({ id: PLENARY_STATUS__PUBLISHED }),
    [SESSION__PLENARY__STATUS__STARTING]: intl.formatMessage({ id: PLENARY_STATUS__STARTING }),
    [SESSION__PLENARY__STATUS__STARTED]: intl.formatMessage({ id: PLENARY_STATUS__STARTED }),
    [SESSION__PLENARY__STATUS__SCHEDULED]: intl.formatMessage({ id: PLENARY_STATUS__SCHEDULED }),
    [SESSION__PLENARY__STATUS__FINISHED]: intl.formatMessage({ id: PLENARY_STATUS__FINISHED }),
    [SESSION__PLENARY__STATUS__CREATED]: intl.formatMessage({ id: PLENARY_STATUS__CREATED }),
    [SESSION__PLENARY__STATUS__ERROR]: intl.formatMessage({ id: PLENARY_STATUS__ERROR }),
  };

  return PLENARY_STATUS_TRANSLATED[statusValue];
};
