import React, { useState } from "react";
import { SELECT } from "./SelectConstants";
import style from "./Select.module.css";

function SelectComponent({
  data,
  fieldLValue = SELECT.FIELD_VALUE,
  fieldLabel = SELECT.FIELD_LABEL,
  handleOnchange,
  labelOption = false,
  defaultValue = "",
  label,
  variant,
}) {
  /*const [selected, setSelected] = useState(null);*/
  return (
    <>
      {label && <label htmlFor={data.name}>{label}</label>}
      <select
        className={style[variant]}
        data-tema-oscuro={false}
        onChange={handleOnchange}
        value={defaultValue}
        name={data.name}
      >
        {labelOption && <option value="">{labelOption}</option>}
        {data.map((item, index) => (
          <option key={index} value={item[fieldLValue]}>
            {item[fieldLabel]}
          </option>
        ))}
      </select>
    </>
  );
}

export default SelectComponent;
