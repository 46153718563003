import { TIIVII_COLOURS, TIIVII_CONFIG, TIIVII_THEME } from "./tenants/tiivii";
import {
  APP_TENANT,
  TENANT_MENORCA,
  TENANT_TIIVII,
  TENANT_TORREVIEJA,
  TENANT_CIUTADELLA,
  TENANT_ALAIOR,
  TENANT_ESCASTELL,
  TENANT_FERRERIES,
  TENANT_CONSELL,
  TENANT_DEFAULT,
} from "../utils/constants";
import { TORREVIEJA_COLOURS, TORREVIEJA_CONFIG, TORREVIEJA_THEME } from "./tenants/torrevieja";
import { MENORCA_COLOURS, MENORCA_CONFIG, MENORCA_THEME } from "./tenants/menorca";
import { CIUTADELLA_COLOURS, CIUTADELLA_CONFIG, CIUTADELLA_THEME } from "./tenants/ciutadella";
import { ALAIOR_COLOURS, ALAIOR_CONFIG, ALAIOR_THEME } from "./tenants/alaior";
import { ESCASTELL_COLOURS, ESCASTELL_CONFIG, ESCASTELL_THEME } from "./tenants/escastell";
import { FERRERIES_COLOURS, FERRERIES_CONFIG, FERRERIES_THEME } from "./tenants/ferreries";
import { CONSELL_COLOURS, CONSELL_CONFIG, CONSELL_THEME } from "./tenants/consell";
import { DEFAULT_COLOURS, DEFAULT_CONFIG, DEFAULT_THEME } from "./tenants/default";

export const DATA_BY_TENANT = {
  [TENANT_TIIVII]: { theme: TIIVII_THEME, config: TIIVII_CONFIG, colours: TIIVII_COLOURS },
  [TENANT_TORREVIEJA]: { theme: TORREVIEJA_THEME, config: TORREVIEJA_CONFIG, colours: TORREVIEJA_COLOURS },
  [TENANT_MENORCA]: { theme: MENORCA_THEME, config: MENORCA_CONFIG, colours: MENORCA_COLOURS },
  [TENANT_CIUTADELLA]: { theme: CIUTADELLA_THEME, config: CIUTADELLA_CONFIG, colours: CIUTADELLA_COLOURS },
  [TENANT_ALAIOR]: { theme: ALAIOR_THEME, config: ALAIOR_CONFIG, colours: ALAIOR_COLOURS },
  [TENANT_ESCASTELL]: { theme: ESCASTELL_THEME, config: ESCASTELL_CONFIG, colours: ESCASTELL_COLOURS },
  [TENANT_FERRERIES]: { theme: FERRERIES_THEME, config: FERRERIES_CONFIG, colours: FERRERIES_COLOURS },
  [TENANT_CONSELL]: { theme: CONSELL_THEME, config: CONSELL_CONFIG, colours: CONSELL_COLOURS },
  [TENANT_DEFAULT]: { theme: DEFAULT_THEME, config: DEFAULT_CONFIG, colours: DEFAULT_COLOURS },
};

export const getTenantTheme = () => {
  if (!APP_TENANT || !DATA_BY_TENANT[APP_TENANT]) return DATA_BY_TENANT[TENANT_TIIVII].theme;

  return DATA_BY_TENANT[APP_TENANT].theme;
};

export const getTenantConfig = () => {
  if (!APP_TENANT || !DATA_BY_TENANT[APP_TENANT]) return DATA_BY_TENANT[TENANT_TIIVII].config;

  return DATA_BY_TENANT[APP_TENANT].config;
};
