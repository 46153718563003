import React, { useEffect, useState } from "react";
import { updateAutoMixingService } from "../../../../../../../../../services/commonservices";
import { useHistory } from "react-router-dom";
import AutomaticProductionOptions from "../AutomaticProductionOptionsComponent";
import { buildUrl } from "../../../../../../../../../utils/axiosRequests";
import { ROUTE_PLENARY_DETAIL } from "../../../../../../../../../utils/routes";

const AutomaticProduction = ({ plenarySessionId }) => {
  const history = useHistory();
  const [automaticProductionOptions, setAutomaticProductionOptions] = useState({
    auto_mixing: true,
    auto_participant_selection: true,
    auto_titler: true,
  });

  useEffect(() => {
    if (plenarySessionId) {
      updateAutoMixingService(plenarySessionId, automaticProductionOptions, () => {
        history.push(buildUrl(ROUTE_PLENARY_DETAIL, { id: plenarySessionId }));
      });
    }
  }, [plenarySessionId]);

  return <AutomaticProductionOptions setOptions={setAutomaticProductionOptions} />;
};

export default AutomaticProduction;
