import React, { useState } from "react";
import { deleteSessionDocument } from "../../../../../services/commonservices";
import DocumentUploadComponent from "./documentUploadComponent/DocumentUploadComponent";
import {
    ROLE_TIIVII_SUPER_USER,
    ROLE_TIIVIIPLEN_ADMIN_USER,
    SESSION__PLENARY__STATUS__FINISHED,
    SESSION__PLENARY__STATUS__PUBLISHED,
    SESSION__PLENARY__STATUS__REVIEWED,
} from "../../../../../utils/constants";
import TypographyComponent from "../../../../shared/typography/TypographyComponent";
import { Paper } from "@material-ui/core";
import { hasRoles } from "../../../../../utils/keycloak";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
    TEXT_VARIANT_H2,
    TEXT_VARIANT_H4,
    TEXT_VARIANT_P,
    TEXT_VARIANT_SPAN,
} from "../../../../shared/typography/constants";
import ButtonComponent from "../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../shared/button/buttonConstants";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from './EditIcon';
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { useParams } from "react-router-dom";
import { getOptions } from "../../../../../utils/axiosRequests";
import {
    BUTTON_DISABLED_BY_USER_PERMISSION,
    COMMON__DELETE,
    COMMON__EDIT,
    COMMON_CANCEL,
    COMMON__DOWNLOAD,
    PLENARY__SESSIONS__ADD_DOCUMENTS,
    PLENARY__SESSIONS__DOCUMENTS,
    PLENARY__SESSIONS__DOCUMENT_DELETE__CONFIRMATION_MODAL__TEXT,
    PLENARY__SESSIONS__DOCUMENT_DELETE__CONFIRMATION_MODAL__TITLE,
} from "../../../../../translations/constans";
import Tooltip from "@mui/material/Tooltip";
import { Box, Modal } from "@mui/material";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";

const documentStylesWhitoutVideo = {
    documentContainer: {
        marginTop: "2rem",
    },
    documentLine: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginRight: "1rem",
        marginLeft: "1rem",
        borderBottom: "0.1px solid rgba(100, 100, 100, 0.2)",
    },
    documentsList: {
        margin: 8,
        padding: 5,
    },
    cardContainer: {
        margin: "var(--spacing-xl)",
        padding: "var(--spacing-xl)",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: "2rem",
        marginLeft: "2rem",
        fontSize: "var(--font-size)",
    },
    modalContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "400",
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "var(--border-radius)",
        p: 4,
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "12px",
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "var(--spacing-md)",
        marginTop: "var(--spacing-xl)",
    },
};

const DocumentManagerComponent = ({
    documents = [],
    isCreatedAndScheduled,
    onReloadData,
    isVideoAreaComponent,
    editableByStatus,
}) => {
    const [isCreateFormOpened, setCreateFormOpened] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [documentId, setDocumentId] = useState(null);
    const [editingDocument, setEditingDocument] = useState(null);
    const keycloak = useSelector((state) => state.common.keycloak);
    const canUploadDocument = hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER]);
    const canAddAndDelete = hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER]);
    const deleteButtonStyles = canAddAndDelete ? {} : documentStylesWhitoutVideo.disabled;
    const { id: plenarySessionId } = useParams();
    const intl = useIntl();

    const sessionData = useSelector((state) => state.sessionDetail.sessionDetailData);

    const handleOpenCreateForm = () => {
        setCreateFormOpened(!isCreateFormOpened);
        setEditingDocument(null);
    };

    const handleUploadSuccess = () => {
        onReloadData();
        setCreateFormOpened(false);
        setEditingDocument(null);
    };

    const deleteDocument = (documentId) => {
        deleteSessionDocument(plenarySessionId, documentId, onReloadData);
        setShowModalDelete(false);
    };

    const handleDownload = ({ download_url, file_name, file_type }) => {
        fetch(download_url, {
            method: "GET",
            ...getOptions({}, false, false),
        })
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `${file_name}.${file_type}`;
                link.style.display = "none"; // Ocultar el enlace
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => console.error("Error fetching file:", error));
    };

    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
    };

    const handleDeleteDocument = (id) => {
        setDocumentId(id);
        setShowModalDelete(true);
    };

    const handleEditDocument = (doc) => {
        setEditingDocument(doc);
        setCreateFormOpened(true);
    };

    return (
        <>
            <Paper elevation={4} style={documentStylesWhitoutVideo.cardContainer}>
                <div style={documentStylesWhitoutVideo.header}>
                    {!isCreateFormOpened && (
                        <TypographyComponent
                            text={intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENTS })}
                            variant={TEXT_VARIANT_H2}
                        />
                    )}
                    {!isCreateFormOpened &&
                        isCreatedAndScheduled &&
                        sessionData.status !== SESSION__PLENARY__STATUS__REVIEWED &&
                        sessionData.status !== SESSION__PLENARY__STATUS__FINISHED &&
                        sessionData.status !== SESSION__PLENARY__STATUS__PUBLISHED && (
                            <Tooltip title={!canAddAndDelete ? intl.formatMessage({ id: BUTTON_DISABLED_BY_USER_PERMISSION }) : ""}>
                                <div>
                                    <ButtonComponent
                                        disabled={!canAddAndDelete}
                                        text={intl.formatMessage({ id: PLENARY__SESSIONS__ADD_DOCUMENTS })}
                                        onClick={handleOpenCreateForm}
                                        variant={editableByStatus && canAddAndDelete ? BUTTON.VARIANT_PRIMARY : BUTTON.VARIANT_DISABLE}
                                        icon={<PostAddIcon />}
                                        size={BUTTON.SIZE__FIT_CONTENT}
                                    />{" "}
                                </div>
                            </Tooltip>
                        )}
                    {!isCreateFormOpened && isVideoAreaComponent && (
                        <ControlPointIcon onClick={handleOpenCreateForm} fontSize={"medium"} color={"secondary"} />
                    )}
                </div>
                {isCreateFormOpened && canUploadDocument ? (
                    <DocumentUploadComponent
                        isCreatedAndScheduled={isCreatedAndScheduled}
                        plenarySessionId={plenarySessionId}
                        onUploadSuccess={handleUploadSuccess}
                        setCreateFormOpened={setCreateFormOpened}
                        editingDocument={editingDocument}
                    />
                ) : (
                    <ul style={documentStylesWhitoutVideo.documentContainer}>
                        {documents.map((doc) => (
                            <div key={doc.id} style={documentStylesWhitoutVideo.documentsList}>
                                <li style={documentStylesWhitoutVideo.documentLine}>
                                    <div>
                                        <span>{doc.file_name}</span>
                                    </div>
                                    <div style={{ display: "flex", gap: "var(--spacing-xl)" }}>
                                        <ButtonComponent
                                            onClick={() => handleEditDocument(doc)}
                                            icon={<EditIcon />}
                                            text={intl.formatMessage({ id: COMMON__EDIT })}
                                            variant={BUTTON.VARIANT_TEXT}
                                            size={BUTTON.SIZE__FIT_CONTENT}
                                        />
                                        <ButtonComponent
                                            onClick={() => handleDownload(doc)}
                                            rel="noopener noreferrer"
                                            icon={<FileDownloadOutlinedIcon />}
                                            text={intl.formatMessage({ id: COMMON__DOWNLOAD })}
                                            variant={editableByStatus ? BUTTON.VARIANT_TEXT : BUTTON.VARIANT_TEXT_DISABLE}
                                            size={BUTTON.SIZE__FIT_CONTENT}
                                        />
                                        {sessionData.status !== SESSION__PLENARY__STATUS__REVIEWED &&
                                            sessionData.status !== SESSION__PLENARY__STATUS__PUBLISHED && (
                                                <Tooltip
                                                    title={!canAddAndDelete ? intl.formatMessage({ id: BUTTON_DISABLED_BY_USER_PERMISSION }) : ""}
                                                >
                                                    <div>
                                                        {" "}
                                                        <ButtonComponent
                                                            disabled={!canAddAndDelete}
                                                            onClick={() => handleDeleteDocument(doc.id)}
                                                            icon={<DeleteOutlineOutlinedIcon />}
                                                            text={intl.formatMessage({ id: COMMON__DELETE })}
                                                            variant={
                                                                editableByStatus && canAddAndDelete ? BUTTON.VARIANT_TEXT : BUTTON.VARIANT_TEXT_DISABLE
                                                            }
                                                            size={BUTTON.SIZE__FIT_CONTENT}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                    </div>
                                </li>
                            </div>
                        ))}
                    </ul>
                )}
            </Paper>
            <div>
                <Modal
                    open={showModalDelete}
                    onClose={handleCloseModalDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={documentStylesWhitoutVideo.modalContainer}>
                        <div style={documentStylesWhitoutVideo.textContainer}>
                            <TypographyComponent
                                text={intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_DELETE__CONFIRMATION_MODAL__TITLE })}
                                variant={TEXT_VARIANT_H2}
                            />
                            <TypographyComponent
                                text={intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_DELETE__CONFIRMATION_MODAL__TEXT })}
                                variant={TEXT_VARIANT_P}
                            />
                        </div>
                        <div style={documentStylesWhitoutVideo.buttonsContainer}>
                            <ButtonComponent
                                onClick={handleCloseModalDelete}
                                variant={BUTTON.VARIANT_SECONDARY}
                                text={intl.formatMessage({ id: COMMON_CANCEL })}
                                icon={<CancelOutlined />}
                                size={BUTTON.SIZE__FIT_CONTENT}
                            />
                            <ButtonComponent
                                onClick={() => deleteDocument(documentId)}
                                variant={BUTTON.VARIANT_PRIMARY}
                                text={intl.formatMessage({ id: COMMON__DELETE })}
                                icon={<CheckCircleOutline />}
                                size={BUTTON.SIZE__FIT_CONTENT}
                            />
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default DocumentManagerComponent;
