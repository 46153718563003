import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { TEXT } from "../shared/typography/typographyConstants";
import TypographyComponent from "../shared/typography/TypographyComponent";
import { useIntl } from "react-intl";

const useStyles = makeStyles(() => ({
  floatingNotification: {
    position: "fixed",
    bottom: "10px",
    right: "-1000px",
    backgroundColor: "red",
    padding: "15px",
    borderRadius: "5px",
    transition: "right 0.5s ease-out",
    "& p": {
      color: "#fff",
      fontWeight: "lighter",
    },
  },
  floatingNotificationActive: {
    right: "10px",
  },
}));
const Notification = ({ message, clearMessage }) => {
  const [isActive, setIsActive] = useState(false);
  const classes = useStyles();
  const intl = useIntl();

  useEffect(() => {
    if (message?.length) {
      setIsActive(true);
      const timeoutId = setTimeout(() => {
        setIsActive(false);
        if (!!clearMessage) {
          clearMessage();
        }
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [message, clearMessage]);

  return (
    <div className={`${classes.floatingNotification} ${isActive ? classes.floatingNotificationActive : ""}`}>
      {!!message && <TypographyComponent text={intl.formatMessage({ id: message })} variant={TEXT.VARIANT_P} />}
    </div>
  );
};

export default Notification;
