export const TEXT = {
  VARIANT_H1: "h1",
  VARIANT_H2: "h2",
  VARIANT_H3: "h3",
  VARIANT_H4: "h4",
  VARIANT_P: "p",
  VARIANT_SPAN: "span",
  VARIANT_SMALL: "small",
  TEXT_DEFAULT: " - ",
};
