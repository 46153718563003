import React from "react";
import PropTypes from "prop-types";
import { TEXT } from "./typographyConstants";

function TypographyComponent({ text = TEXT.TEXT_DEFAULT, variant = TEXT.VARIANT_P, className, style }) {
  return React.createElement(variant, { className, style }, text);
}

export default TypographyComponent;
TypographyComponent.propTypes = {
  text: PropTypes.any,
  variant: PropTypes.oneOf([
    TEXT.VARIANT_H1,
    TEXT.VARIANT_H2,
    TEXT.VARIANT_H3,
    TEXT.VARIANT_H4,
    TEXT.VARIANT_P,
    TEXT.VARIANT_SPAN,
    TEXT.VARIANT_SMALL,
  ]),
  className: PropTypes.string,
};
