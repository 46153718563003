import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, MenuItem, Select, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../../../../../../shared/button/ButtonComponent";
import style from "./ParticipantTable.module.css";
import { BUTTON } from "../../../../../../shared/button/buttonConstants";
import {
  COMMON__EDIT,
  COMMON__DELETE,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_NAME,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_CHARGE,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_GAME,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_MICROPHONE,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_DECREASING,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_UPWARD,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_FILTER,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_HIDE,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_MANAGE_COLUMNS,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_ROWS_PER_PAGE,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_OF,
  PLENARY__SESSIONS__PARTICIPANTS_TABLE_NO_MICROPHONE,
  PLENARY_SESSIONS_PARTICIPANTS_TABLE_CAPTION,
  PARTICIPANTS__CLOSE_UP,
  MESSAGE_TOOLTIP_EDIT_PARTICIPANTS,
  MESSAGE_TOOLTIP_DELETE_PARTICIPANTS
} from "../../../../../../../translations/constans";
import { useIntl } from "react-intl";
import {
  updateParticipantMicroActionCreator,
  updateParticipantMicroActionCloseCreator,
  participants
} from '../../../../../../../reducers/sessionDetal';
import { selectCurrentLanguage } from '../../../../../../../reducers/commonState';
import { removeParticipantMicrophoneService } from "../../../../../../../services/commonservices";
import { APP_TENANT } from "../../../../../../../utils/constants";
import Tooltip from "@mui/material/Tooltip";

const createColumns = (intl, seats, rows, onDeleteParticipant, onEditParticipant, editableByStatus, handleChangeMicro, handleToggleCaptionEnabled, handleToggleCloseUp, language) => [
  {
    field: "id",
    headerName: "",
    flex: 0.1,
    hide: true,
    renderCell: () => <div />,
  },
  {
    field: "photo_url",
    headerName: "",
    flex: 1,
    renderCell: (params) => <Avatar src={`data:image/jpeg;base64,${params.row.photo_url}`} />,
  },
  {
    field: "name",
    headerName: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_NAME }),
    minWidth: 150,
    flex: 2,
  },
  {
    field: "title_translations",
    headerName: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_CHARGE }),
    minWidth: 150,
    flex: 2,
    renderCell: (params) => {
      const title = params.row.title_translations[language] || '';
      return <span>{title}</span>;
    },
  },
  {
    field: "organization",
    headerName: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_GAME }),
    minWidth: 100,
    flex: 1,
  },
  {
    field: "seat_id",
    headerName: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_MICROPHONE }),
    minWidth: 160,
    flex: 1,
    renderCell: (params) => {
      const isSpecialParticipant = ["Receso", "Público", "Votación"].includes(params.row.name);
      return (
        <Select
          defaultValue=""
          onChange={(event) => handleChangeMicro(params.row.id, event.target.value)}
          className={style.microSelector}
          value={params.row.seat_id === null ? "none" : params.row.seat_id}
          disabled={isSpecialParticipant || !seats}
        >
          <MenuItem value="none" className={style.boldText}>
            {intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_NO_MICROPHONE })}
          </MenuItem>
          {(seats || []).map((seat) => (
            <MenuItem key={seat.id} value={seat.id}>
              {seat.name}
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
  {
    field: "caption_enabled",
    headerName: intl.formatMessage({ id: PLENARY_SESSIONS_PARTICIPANTS_TABLE_CAPTION }),
    cellClassName: style.rightBorder,
    minWidth: 61,
    maxWidth: 41,
    renderCell: (params) => {
      const isSpecialParticipant = ["Receso", "Público", "Votación"].includes(params.row.name);
      const isMicrophoneDisabled = params.row.seat_id === null;
      const isCaptionEnabled = !isMicrophoneDisabled && params.row.caption_enabled;

      return (
        <Checkbox
          checked={isCaptionEnabled}
          onChange={(event) => handleToggleCaptionEnabled(params.row.id, event.target.checked)}
          color="primary"
          disabled={isSpecialParticipant || !editableByStatus || isMicrophoneDisabled}
        />
      );
    },
  },
  {
    field: "close_up",
    headerName: intl.formatMessage({ id: PARTICIPANTS__CLOSE_UP }),
    cellClassName: style.rightBorder,
    minWidth: 111,
    maxWidth: 180,
    renderCell: (params) => {
      const isSpecialParticipant = ["Receso", "Público", "Votación"].includes(params.row.name);
      const isMicrophoneDisabled = params.row.seat_id === null;

      return (
        <div className={style.checkboxContainer}>
          <Checkbox
            checked={!isMicrophoneDisabled && params.row.close_up}
            onChange={(event) => handleToggleCloseUp(params.row.id, event.target.checked)}
            color="primary"
            disabled={isSpecialParticipant || !editableByStatus || isMicrophoneDisabled}
          />
        </div>
      );
    },
  },
  {
    field: "edit",
    headerName: "",
    flex: 1.5,
    renderCell: (params) => {
      const specialParticipants = ["Receso", "Público", "Votación", "Varios micrófonos simultáneos"].includes(params.row.name);
      return (
        <div>
          <Tooltip title={intl.formatMessage({id: MESSAGE_TOOLTIP_EDIT_PARTICIPANTS})}
                   disableHoverListener={specialParticipants === false}
          >
            <div>
              <ButtonComponent
                  color="primary"
                  text={intl.formatMessage({id: COMMON__EDIT})}
                  disabled={!editableByStatus || specialParticipants}
                  variant={specialParticipants ? BUTTON.VARIANT_DISABLE : editableByStatus ? BUTTON.VARIANT_DANGER : BUTTON.VARIANT_DISABLE}
                  onClick={() => onEditParticipant(params.row)}
                  size={BUTTON.SIZE__FIT_CONTENT}
              />
            </div>
          </Tooltip>
        </div>
      )
    },
  },
  {
    field: "delete",
    headerName: "",
    flex: 1.5,

    renderCell: (params) => {
      const specialParticipants = ["Receso", "Público", "Votación", "Varios micrófonos simultáneos"].includes(params.row.name);
      return (
          <div>
            <Tooltip
                title={intl.formatMessage({ id: MESSAGE_TOOLTIP_DELETE_PARTICIPANTS })}
                disableHoverListener={specialParticipants === false}
            >
              <div>
                <ButtonComponent
                    text={intl.formatMessage({ id: COMMON__DELETE })}
                    disabled={!editableByStatus || specialParticipants}
                    variant={specialParticipants ? BUTTON.VARIANT_DISABLE : editableByStatus ? BUTTON.VARIANT_DANGER : BUTTON.VARIANT_DISABLE}
                    onClick={() => onDeleteParticipant(params.id)}
                    size={BUTTON.SIZE__FIT_CONTENT}
                />
              </div>
            </Tooltip>
          </div>
      );
    }
  },
];
const compareMicroNames = (a, b) => {
  const splitName = (name) => {
    return name.split(/(\d+)/).map((part) => (isNaN(part) ? part : parseInt(part, 10)));
  };

  const aParts = splitName(a);
  const bParts = splitName(b);

  for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
    if (aParts[i] === undefined) return -1;
    if (bParts[i] === undefined) return 1;
    if (aParts[i] < bParts[i]) return -1;
    if (aParts[i] > bParts[i]) return 1;
  }

  return 0;
};

const ParticipantTable = ({
  seats,
  plenarySessionId,
  onDeleteParticipant,
  onEditParticipant,
  editableByStatus,
  handleSaveConfiguration,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentLanguage = useSelector(selectCurrentLanguage);
  const rows = useSelector((state) => state.sessionDetail.participants);

  const [sortedParticipants, setSortedParticipants] = useState([]);

  const getMicroName = (seatId) => {
    if (!seats) return intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_NO_MICROPHONE });
    const seat = seats.find((seat) => seat.id === seatId);
    return seat ? seat.name : intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_NO_MICROPHONE });
  };

  const sortParticipants = (participants) => {
    return participants.slice().sort((a, b) => {
      const aHasSeatId = a.seat_id !== null;
      const bHasSeatId = b.seat_id !== null;

      if (aHasSeatId && bHasSeatId) {
        const aMicroName = getMicroName(a.seat_id);
        const bMicroName = getMicroName(b.seat_id);
        const nameComparison = compareMicroNames(aMicroName, bMicroName);
        return nameComparison !== 0 ? nameComparison : a.name.localeCompare(b.name);
      } else if (aHasSeatId) {
        return -1;
      } else if (bHasSeatId) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  };

  useEffect(() => {
    setSortedParticipants(sortParticipants(rows));
  }, [rows, seats]);

  const handleChangeMicro = (participantId, seat_id) => {
    const updatedParticipant = rows.find((participant) => participant.id === participantId);
    if (!updatedParticipant) return;

    let caption_enabled = seat_id !== "none";
    let close_up = seat_id !== "none";

    if (seat_id === "none") {
      seat_id = null;
      caption_enabled = false;
      close_up = false;
      removeParticipantMicrophoneService(
        APP_TENANT,
        plenarySessionId,
        participantId,
        () => {
          dispatch(updateParticipantMicroActionCreator({
            participantId,
            seat_id: null,
            caption_enabled: false,
            close_up: false,
          }));
          handleSaveConfiguration();
        }
      );
      return;
    }

    dispatch(updateParticipantMicroActionCreator({
      participantId,
      seat_id,
      caption_enabled,
      close_up,
    }));

    handleSaveConfiguration();
  };

  const handleToggleCaptionEnabled = (participantId, enabled) => {
    const updatedParticipant = rows.find((participant) => participant.id === participantId);
    if (!updatedParticipant) return;

    dispatch(updateParticipantMicroActionCreator({
      participantId,
      seat_id: updatedParticipant.seat_id,
      caption_enabled: enabled,
      close_up: updatedParticipant.close_up,
    }));

    handleSaveConfiguration();
  };

  const handleToggleCloseUp = (participantId, enabled) => {
    const updatedParticipant = rows.find((participant) => participant.id === participantId);
    if (!updatedParticipant) return;

    dispatch(updateParticipantMicroActionCloseCreator({
      participantId,
      seat_id: updatedParticipant.seat_id,
      close_up: enabled,
    }));

    handleSaveConfiguration();
  };

  return (
    <div style={{ minHeight: 400 }}>
      <DataGrid
        rows={sortedParticipants}
        columns={createColumns(intl, seats, rows, onDeleteParticipant, onEditParticipant, editableByStatus, handleChangeMicro, handleToggleCaptionEnabled, handleToggleCloseUp, currentLanguage)}
        disableSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
            page: 0,
            pageSize: 25,
          },
        },
        }}
        pageSizeOptions={[5, 15, 25]}
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_ROWS_PER_PAGE }),
            labelDisplayedRows({ from, to, count }) {
            return `${from}–${to} ${intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_OF })} ${count !== -1 ? count : `more than ${to}`}`;
          },
        },
        columnMenuUnsort: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_DECREASING }),
        columnMenuSortAsc: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_UPWARD }),
        columnMenuSortDesc: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_DECREASING }),
        columnMenuFilter: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_FILTER }),
        columnMenuHideColumn: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_HIDE }),
        columnMenuManageColumns: intl.formatMessage({ id: PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_MANAGE_COLUMNS }),
      }}
      />
    </div>
  );
};

export default ParticipantTable;
