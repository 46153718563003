import React, { useState } from "react";
import PropTypes from "prop-types";

import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { APP_BAR_POSITION, LANGUAGE_ICON_ARIA_LABEL, LANGUAGE_ICON_COLOR } from "./topMenuConstants";
import LanguageMenuComponent from "./components/languageMenuComponent/LanguageMenuComponent";

import { getTenantConfig } from "../../../customization/utils";
import { useSelector } from "react-redux";
import styles from "./topMenuComponent.module.css";
import LANGUAGE_ICON from "../../../resources/LanguageIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import { useKeycloak } from "@react-keycloak/web";
import { I18N_CONFIG, LOCAL_STORAGE_ITEMS } from "../../../utils/constants";

const TopMenuComponent = ({ handleLanguageChanged }) => {
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const availableLanguages = useSelector((state) => state.common.availableLanguages);
  const { keycloak } = useKeycloak();

  const selectedLanguage = localStorage.getItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_LOCALE) || 'es';
  const handleLanguageMenu = (event) => {
    setLanguageMenuAnchor(event ? event.currentTarget : null);
  };

  const handleMainMenu = (open) => setMainMenuOpen(open);

  const handleClose = () => {
    handleMainMenu(false);
  };
  const closeSession = () => {
    keycloak.logout();
    handleClose();
  };

  return (
    <div className={styles.rootPlenary}>
      <AppBar position={APP_BAR_POSITION} className={styles.appBarPlenary}>
        <Toolbar className={styles.toolbarPlenary}>
          <img alt="" src={getTenantConfig("tiivii").headerData.logo.src} className={styles.imageHeader} />
          <div>
            <IconButton
              color={LANGUAGE_ICON_COLOR}
              aria-label={LANGUAGE_ICON_ARIA_LABEL}
              className={styles.languageButton}
              onClick={handleLanguageMenu}
            >
              <div className={styles.iconLanguage} style={{ color: "var(--color-light)" }}>
                {LANGUAGE_ICON}
              </div>

              <Typography style={{ color: "var(--color-light)" }}>{selectedLanguage}</Typography>
            </IconButton>
            <IconButton
              className={styles.menuButton}
              color="var(--color-light)"
              aria-label="Menu"
              onClick={closeSession}
            >
              <LogoutIcon sx={{ color: "var(--color-light)" }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div>
        <LanguageMenuComponent
          anchor={languageMenuAnchor}
          handleClose={() => handleLanguageMenu(null)}
          handleLanguageChanged={handleLanguageChanged}
          i18nConfig={I18N_CONFIG}
          availableLanguages={availableLanguages}
        />
      </div>
    </div>
  );
};

TopMenuComponent.propTypes = {
  handleLanguageChanged: PropTypes.func.isRequired,
};

export default TopMenuComponent;
