import React, { useEffect, useState } from "react";
import MinutesManagerComponent from "../minutesManagerComponent/MinutesManagerComponent";
import { createMinuteService, updateMinuteService } from "../../../../../../../services/commonservices";
import { SESSION_MINUTES_FROM } from "../../../../../../../utils/constants";
import ButtonComponent from "../../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../../shared/button/buttonConstants";
import TypographyComponent from "../../../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_P } from "../../../../../../shared/typography/constants";
import { useIntl } from "react-intl";
import { COMMON__DOWNLOAD, COMMON__MP3, COMMON__MP4, SET_TIME } from "../../../../../../../translations/constans";
import style from "./EditIntervention.module.css";
import SEND_ICON from "../../../../../../../resources/SendIcon";
import { convertTimeRounded } from "../../../../../../../utils/utils";

const EditIntervention = ({
  agendaId,
  audioUrl,
  isIntervention,
  isSelected,
  plenarySessionId,
  minuteId,
  timingFrom,
  timingTo,
  videoRef,
  videoUrl,
  editableByStatus,
  buttonDisabled,
  statusReviewedPublished,
  previousPoint,
  reloadData,
  setHasChanged,
}) => {
  const intl = useIntl();
  const [newTimingFrom, setNewTimingFrom] = useState(timingFrom);
  const [newTimingTo, setNewTimingTo] = useState(timingTo);

  const handleNewTimeFrom = (time) => {
    setNewTimingFrom(time);
    if (isIntervention) setHasChanged(true);
  };
  const handleNewTimingTo = (time) => {
    setNewTimingTo(time);
    if (isIntervention) setHasChanged(true);
  };
  useEffect(() => {
    if (newTimingFrom !== timingFrom) handleNewTimeFrom(timingFrom);
    if (newTimingTo !== timingTo) handleNewTimingTo(timingTo);
  }, [timingFrom, timingTo]);

  const changePreviousPointEndTime = (timer, timeConverted) => {
    if (timer === SESSION_MINUTES_FROM && !!previousPoint && previousPoint?.timing_to === 0) {
      let body = {
        relative_end_time: timeConverted,
        relative_init_time: previousPoint?.timing_from,
        agenda_point_id: agendaId,
      };
      updateMinuteService(plenarySessionId, previousPoint.minute_id, body, reloadData);
    }
  };

  const handleSaveTime = (time, timer) => {
    let body = {
      relative_end_time: !!newTimingTo ? convertTimeRounded(newTimingTo) : 0,
      relative_init_time: !!newTimingFrom ? convertTimeRounded(newTimingFrom) : 0,
      agenda_point_id: agendaId,
    };
    const timeConverted = convertTimeRounded(time);
    if (timer === SESSION_MINUTES_FROM) {
      handleNewTimeFrom(time);
      body.relative_init_time = timeConverted;
    } else {
      handleNewTimingTo(time);
      body.relative_end_time = timeConverted;
    }
    !!minuteId
      ? updateMinuteService(plenarySessionId, minuteId, body, () => changePreviousPointEndTime(timer, timeConverted))
      : createMinuteService(plenarySessionId, body, () => changePreviousPointEndTime(timer, timeConverted));
  };

  const handleTimeChange = (time, timer) => {
    !!agendaId && handleSaveTime(time, timer);
  };

  const handleFixTime = () => {
    videoRef.current.seekTo(newTimingFrom / 1000, "seconds");
    handleNewTimeFrom(videoRef.current.getCurrentTime() * 1000);
  };
  function getMediaService(url) {
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <div className={style.editIntervention}>
      <div className={style.buttonContainer}>
        <ButtonComponent
          text={intl.formatMessage({ id: SET_TIME })}
          icon={SEND_ICON}
          onClick={handleFixTime}
          variant={BUTTON.VARIANT_SECONDARY}
        />
      </div>
      <MinutesManagerComponent
        isCreating={!agendaId}
        isIntervention
        timingFrom={newTimingFrom}
        timingTo={newTimingTo}
        videoRef={videoRef}
        handleSave={handleSaveTime}
        onTimeChange={handleTimeChange}
        editableByStatus={editableByStatus}
        buttonDisabled={buttonDisabled}
        statusReviewedPublished={statusReviewedPublished}
      />
      <div className={style.downloadText}>
        <div className={style.downloadLine}>
          <TypographyComponent text={intl.formatMessage({ id: COMMON__DOWNLOAD })} variant={TEXT_VARIANT_P} />
        </div>
        <div className={style.downloadButton}>
          <ButtonComponent
            onClick={() => getMediaService(audioUrl)}
            text={intl.formatMessage({ id: COMMON__MP3 }).toUpperCase()}
            variant={!!audioUrl ? BUTTON.VARIANT_SECONDARY : BUTTON.VARIANT_DISABLE}
            size={BUTTON.SIZE__FIT_CONTENT}
            disabled={!audioUrl}
          />
          <ButtonComponent
            onClick={() => getMediaService(videoUrl)}
            text={intl.formatMessage({ id: COMMON__MP4 }).toUpperCase()}
            variant={!!videoUrl ? BUTTON.VARIANT_SECONDARY : BUTTON.VARIANT_DISABLE}
            size={BUTTON.SIZE__FIT_CONTENT}
            disabled={!videoUrl}
          />
        </div>
      </div>
    </div>
  );
};

export default EditIntervention;
