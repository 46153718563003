import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const AlertDialog = ({ buttonCloseText, description, onClose, open, title }) => {
  return (
    <Dialog open={open} keepMounted onClose={onClose} aria-labelledby="alert-dialog-slide-title">
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          {buttonCloseText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  buttonCloseText: PropTypes.element.isRequired,
  description: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.element.isRequired,
};

export default AlertDialog;
