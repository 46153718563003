import { configureStore } from "@reduxjs/toolkit";
import commonReducer from "../reducers/commonState";
import sessionDetailReducer from "../reducers/sessionDetal";

const store = configureStore({
  reducer: {
    common: commonReducer,
    sessionDetail: sessionDetailReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["common/storeKeycloakActionCreator"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["common.keycloak"],
        // Ignore these paths in the state
        ignoredPaths: ["common.keycloak"],
      },
    }),
});

export default store;
