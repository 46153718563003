import React, { useState, useEffect } from "react";
import { Divider, Paper, Typography } from "@mui/material";
import style from "./Participants.module.css";
import {
  getParticipantsService,
  updateActiveStateParticipantService,
} from "../../../../../../../../../services/commonservices";
import ParticipantsListComponent from "./componentes/participantsListComponent/ParticipantsListComponent";
import TypographyComponent from "../../../../../../../../shared/typography/TypographyComponent";
import { useIntl } from "react-intl";
import { PARTICIPANTS__TITLE } from "../../../../../../../../../translations/constans";
import { TEXT_VARIANT_H2 } from "../../../../../../../../shared/typography/constants";

const ParticipantsComponent = ({ activeAgendaItemId, plenarySessionId, plenaryTitle }) => {
  const intl = useIntl();
  const [participants, setParticipants] = useState([]);
  const [activeParticipantId, setActiveParticipantId] = useState(null);

  const handleParticipants = (response) => {
    setParticipants(response.data);
  };

  const handleActiveParticipant = (participantId) => {
    getParticipantsService(plenarySessionId, handleParticipants);
    setActiveParticipantId(participantId);
  };

  useEffect(() => {
    getParticipantsService(plenarySessionId, handleParticipants);
  }, []);

  const updateSelectParticipant = (participantId) => {
    updateActiveStateParticipantService(plenarySessionId, participantId, handleActiveParticipant);
  };

  const persons = participants.filter((participant) => participant.type === "person");
  const voting = participants.filter((participant) => participant.type === "voting");
  const other = participants.filter((participant) => participant.type === "other");

  return (
    <Paper
      elevation={4}
      style={{
        padding: "var(--spacing-xl)",
      }}
    >
      <TypographyComponent text={intl.formatMessage({ id: PARTICIPANTS__TITLE })} variant={TEXT_VARIANT_H2} />
      {!!persons.length && (
        <ParticipantsListComponent
          activeParticipantId={activeParticipantId}
          participants={persons}
          plenarySessionId={plenarySessionId}
          plenaryTitle={plenaryTitle}
          onUpdateSelectParticipant={updateSelectParticipant}
        />
      )}
      {!!persons.length && <Divider className={style.divider} />}
      {!!other.length && (
        <ParticipantsListComponent
          activeParticipantId={activeParticipantId}
          participants={other}
          plenarySessionId={plenarySessionId}
          plenaryTitle={plenaryTitle}
          onUpdateSelectParticipant={updateSelectParticipant}
        />
      )}
      {!!other.length && <Divider className={style.divider} />}
      {!!voting.length && (
        <ParticipantsListComponent
          activeAgendaItemId={activeAgendaItemId}
          activeParticipantId={activeParticipantId}
          participants={voting}
          plenarySessionId={plenarySessionId}
          plenaryTitle={plenaryTitle}
          onUpdateSelectParticipant={updateSelectParticipant}
        />
      )}
    </Paper>
  );
};
export default ParticipantsComponent;
