import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
    ADD__PLENARY,
    COMMISSIONS,
    COMMON__BACK,
    COMMON_CANCEL,
    COMMON_SAVE,
    EMAIL,
    END__DATE,
    INFORMATION__PLENARY,
    NEW__PLENARY,
    ORDINARY__PLENARY,
    PLENARY__SESSIONS__COMMISSION_EXTERNAL__URL,
    PRIVATE_SESSION,
    SELECT__COMMISSION,
    START__DATE,
    SUBTITLE,
    TITLE,
    WRITE__EMAIL,
    WRITE__SUBTITLE,
    WRITE__TITLE,
    LANGUAGE_REALIZATION_LABELS,
    TITLE_SUBTITLE_REQUIRED_LEGEND,
    COMMON__INT_CA,
    COMMON__INT_GL,
    COMMON__INT_ES,
    COMMON__INT_EN,
    COMMON__INT_EU,
    FORM_ERROR_TITLE_SUBTITLE_REQUIRED,
    POINT_REQUIRED_LEGEND
} from "../../../translations/constans";
import ButtonComponent from "../../shared/button/ButtonComponent";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_PLENARY_DETAIL, ROUTE_PLENARY_LIST } from "../../../utils/routes";
import { BUTTON } from "../../shared/button/buttonConstants";
import BACK_ICON from "../../../resources/BackIcon";
import styles from "./PlenaryCreation.module.css";
import RoomsComponent from "../roomsComponent/RoomsComponent";
import { Card, CardContent, Checkbox, Divider, FormControlLabel, Tab, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { INPUT_TYPE_TEXT, ROLES_ALL, SESSION_INFORMATION__URL_VIDEO } from "../../../utils/constants";
import { addPlenarySessions, getUserGroupsService, updatePlenaryInformation } from "../../../services/commonservices";
import { Autocomplete, TabContext, TabList, TabPanel } from "@material-ui/lab";
import PageTitleComponent from "../../shared/pageTitle/PageTitleComponent";
import TypographyComponent from "../../shared/typography/TypographyComponent";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import withAuthorization from "../auth/withAuthorization";
import * as Yup from "yup";
import { buildUrl } from "../../../utils/axiosRequests";
import clsx from "clsx";
import CHECK_ICON from "../../../resources/CheckIcon";
import CANCEL_ICON from "../../../resources/CancelIcon";
import { TEXT } from "../../shared/typography/typographyConstants";
import Notification from "../Notification";
import dayjs from "dayjs";
import AutomaticProductionOnCreation from "../../pages/plenaryDetail/components/inDirectComponent/components/videoAreaComponent/components/automaticProductionOnCreationComponent/AutomaticProductionOnCreationComponent";
import AutomaticProduction from "../../pages/plenaryDetail/components/inDirectComponent/components/videoAreaComponent/components/automaticProductionComponent/AutomaticProductionComponent";
import SEND_ICON_WHITE from "../../../resources/SenIconWhite";

const useStyles = makeStyles(() => ({
    auto: {
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: "4px",
        },
        "& .MuiOutlinedInput-input": {
            padding: "13px",
            width: "100%",
        },
        "& .MuiInputBase-input": {
            fontWeight: "normal",
        },
    },
    edit: {
        "& .MuiInputBase-input": {
            fontWeight: "normal",
        },
        editDate: {
            "& .MuiInputBase-input": {
                fontWeight: "normal",
            },
        },
    },
}));
const PlenaryCreationComponent = ({ creationPlenary, infoPlenary }) => {
    const classes = useStyles();
    let { id } = useParams();
    const intl = useIntl();
    const history = useHistory();
    const [commissions, setCommissions] = useState([]);
    const [selectedCommission, setSelectedCommission] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState("");
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState({});
    const [subTitle, setSubTitle] = useState({});
    const [privateSession, setPrivateSession] = useState(false);
    const [initDate, setInitDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [key, setKey] = useState(new Date());
    const [formError, setFormError] = useState("");
    const [plenarySessionId, setPlenarySessionId] = useState("");
    const [urlExternalVideo, setUrlExternalVideo] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [isLanguageEditable, setIsLanguageEditable] = useState(true);

    const defaultLanguage = useSelector((state) => state.common.defaultLanguage);
    const availableLanguages = useSelector((state) => state.common.availableLanguages);

    const initialTabIndex = availableLanguages.findIndex((lang) => lang === defaultLanguage);
    const [val, setVal] = useState(initialTabIndex.toString());
    /*const formattedDateTime = format(new Date(infoPlenary.init_date * 1000), 'dd-MM-yyyy HH:mm:ss');*/
    const languageMap = {
        es: intl.formatMessage({ id: COMMON__INT_ES }),
        en: intl.formatMessage({ id: COMMON__INT_EN }),
        gl: intl.formatMessage({ id: COMMON__INT_GL }),
        ca: intl.formatMessage({ id: COMMON__INT_CA }),
        eu: intl.formatMessage({ id: COMMON__INT_EU }),
    };

    useEffect(() => {
        setSelectedLanguage(defaultLanguage);
        if (infoPlenary !== null) {
            setCommissions(infoPlenary.user_group_id);
            setPrivateSession(infoPlenary.private_session);
            setTitle(infoPlenary.title_translations);
            setSubTitle(infoPlenary.subtitle_translations);
            setInitDate(infoPlenary.init_date ? new Date(infoPlenary.init_date * 1000).toISOString() : "");
            setEndDate(infoPlenary.end_date ? new Date(infoPlenary.end_date * 1000).toISOString() : "");
            setUrlExternalVideo(infoPlenary.vod_url);
            setSelectedLanguage(infoPlenary.language || defaultLanguage);
            if (["CREATED", "SCHEDULED", "STARTING", "STARTED"].includes(infoPlenary.status)) {
                setIsLanguageEditable(true);
            } else {
                setIsLanguageEditable(false);
            }
        }
    }, [infoPlenary, defaultLanguage]);

    const handleChange = (newValue) => {
        setVal(newValue);
        setKey(new Date());
    };
    const handleGoBack = () => {
        history.push(ROUTE_PLENARY_LIST);
    };

    const handleGoBackEdit = () => {
        history.push(buildUrl(ROUTE_PLENARY_DETAIL, { id: infoPlenary?.id }));
    };

    const commissionsCallback = (r) => {
        setCommissions(r.data);
    };
    const getCommissions = () => {
        getUserGroupsService(commissionsCallback);
    };

    useEffect(() => {
        if (!commissions?.length) getCommissions();
    }, []);

    const handleSelectionCommission = (id) => {
        setSelectedCommission(+id);
    };

    const handleEmailChange = (email) => {
        setEmail(email);
    };
    const handleTitleChange = (language, value) => {
        const copy = { ...title };
        copy[language] = value;
        setTitle(copy);
    };
    const handleSubTitleChange = (language, value) => {
        const copy = { ...subTitle };
        copy[language] = value;
        setSubTitle(copy);
    };
    const handleInitDateChange = (initDate) => {
        setInitDate(Date.parse(initDate.$d));
    };
    const handleEndDateChange = (endDate) => {
        setEndDate(Date.parse(endDate.$d));
    };
    const handleChangeURLVideo = (externalURLVideo) => {
        setUrlExternalVideo(externalURLVideo);
    };
    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };
    const renderFormError = (error) => {
        if (error.path === "plenary_room_id" && error.type === "required") {
            return "form_message_plenary_room_id";
        }

        if (error.path === `title.${defaultLanguage}` && error.type === "optionality") {
            return "form_message_title";
        }

        if (error.path === `subtitle.${defaultLanguage}` && error.type === "optionality") {
            return "form_message_subtitle";
        }

        if (error.path === "init_date") {
            if (!new Date(error.params.value).getTime()) {
                return "form_message_start_date";
            }
        }

        if (error.path === "end_date" && error.type === "min") {
            return "form_message_end_date_vs_start_date";
        }

        if (error.path === "end_date") {
            if (!new Date(error.params.value).getTime()) {
                return "form_message_end_date";
            }
        }
        if (error.path === "init_date" && error.params.value >= error.params.value.end_date) {
            return "form_message_end_date_vs_start_date";
        }

        if (error.path === "init_date" && error.params.value < new Date().setHours(0, 0, 0, 0) / 1000) {
            return "form_message_start_date_vs_today";
        }

        if (error.path === "end_date" && error.params.value < new Date().setHours(0, 0, 0, 0) / 1000) {
            return "form_message_end_date_vs_today";
        }
        if (error.path === "email") {
            return "form_message_email";
        }
    };
    const renderOptions = () => {
        const obj = {};
        for (const lang of availableLanguages) {
            if (defaultLanguage === lang) {
                obj[lang] = Yup.string().required();
            } else {
                obj[lang] = Yup.string();
            }
        }
        return obj;
    };

    const validationSchema = Yup.object({
        email: Yup.string().email(),
        plenary_room_id: Yup.string().required(),
        private_session: Yup.boolean().required(),
        init_date: Yup.number().required(),
        end_date: Yup.number()
            .required()
            .when("init_date", (init_date, schema) => {
                return schema.min(init_date[0], "end_date debe ser mayor que init_date");
            }),
        subtitle: Yup.object().shape(renderOptions()).required(),
        title: Yup.object().shape(renderOptions()).required(),
        user_group_id: Yup.number().nullable(),
        language: Yup.string().required(),
        //abstract: Yup.object(),
    });
    const formatBody = (object) => {
        const aux = {};
        for (const lang of Object.keys(object)) {
            //lang=es,en,gl
            if (object[lang] !== "") {
                aux[lang] = object[lang];
            }
        }
        return aux;
    };
    const handleCreatePlenary = () => {
        setFormError("");
        if (!title[defaultLanguage] || !subTitle[defaultLanguage]) {
            setFormError(
                intl.formatMessage({ id: FORM_ERROR_TITLE_SUBTITLE_REQUIRED }, { language: defaultLanguage })
            );
            return;
        }

        if (!title[selectedLanguage] || !subTitle[selectedLanguage]) {
            setFormError(
                intl.formatMessage({ id: FORM_ERROR_TITLE_SUBTITLE_REQUIRED }, { language: selectedLanguage })
            );
            return;
        }
        const body = {
            email: email,
            end_date: new Date(endDate).getTime() / 1000,
            plenary_room_id: selectedRoom,
            private_session: privateSession,
            init_date: new Date(initDate).getTime() / 1000,
            subtitle: formatBody(subTitle),
            title: formatBody(title),
            user_group_id: selectedCommission,
            vod_url: !!urlExternalVideo ? urlExternalVideo : null,
            language: selectedLanguage,
            //abstract:
        };
        validationSchema
            .validate(body)
            .then((validData) => {
                addPlenarySessions(body, addPlenarySessionsCallback);
            })
            .catch((error) => {
                const formattedError = renderFormError(error);
                setFormError(formattedError);
            });
    };

    const validationSchemaEdit = Yup.object().shape({
        init_date: Yup.number(),
        end_date: Yup.number()
            .required()
            .when("init_date", (init_date, schema) => {
                return schema.min(init_date[0], "end_date debe ser mayor que init_date");
            }),
        private_session: Yup.boolean(),
        subtitle_translations: Yup.object().shape(renderOptions()),
        title_translations: Yup.object().shape(renderOptions()),
        language: Yup.string().required(),
        //abstract: Yup.object(),
    });

    const handleEditPlenary = () => {
        setFormError("");

        if (!title[defaultLanguage] || !subTitle[defaultLanguage]) {
            setFormError(
                intl.formatMessage({ id: POINT_REQUIRED_LEGEND }, { language: defaultLanguage })
            );
            return;
        }
        if (!title[selectedLanguage] || !subTitle[selectedLanguage]) {
            setFormError(
                intl.formatMessage({ id: POINT_REQUIRED_LEGEND }, { language: selectedLanguage })
            );
            return;
        }
        const body = {
            end_date: new Date(endDate).getTime() / 1000,
            private_session: privateSession,
            init_date: new Date(initDate).getTime() / 1000,
            subtitle_translations: formatBody(subTitle),
            title_translations: formatBody(title),
            vod_url: !!urlExternalVideo ? urlExternalVideo : null,
            language: selectedLanguage,
            //abstract:
        };
        validationSchemaEdit
            .validate(body)
            .then(() => {
                updatePlenaryInformation(id, body, updatePlenaryInformationCallback);
            })
            .catch((error) => {
                const formattedError = renderFormError(error);
                setFormError(formattedError);
            });
    };
    const updatePlenaryInformationCallback = () => {
        history.push(buildUrl(ROUTE_PLENARY_DETAIL, { id: id }));
    };

    const addPlenarySessionsCallback = (response) => {
        setPlenarySessionId(response.data.id);
        history.push(buildUrl(ROUTE_PLENARY_DETAIL, { id: response.data.id }));
    };

    const handleSetSelectedRoom = (selectedRoomId) => {
        setSelectedRoom(selectedRoomId);
    };

    const placeholderStyle = {
        fontStyle: "italic",
        fontFamily: "var(--font-family)",
    };
    const renderTabLabel = (language) => {
        const isDefaultLang = language === defaultLanguage;
        const isRealizationLang = language === selectedLanguage;

        return (
            <span>
                {languageMap[language] || language}
                {(isDefaultLang || isRealizationLang) && <span style={{ color: 'red' }}> *</span>}
            </span>
        );
    };

    return (
        <>
            <div className={styles.page}>
                <div className={styles.title}>
                    <div className={styles.buttonBack}>
                        <ButtonComponent
                            text={intl.formatMessage({ id: COMMON__BACK })}
                            icon={BACK_ICON}
                            variant={BUTTON.VARIANT_TEXT}
                            onClick={!!infoPlenary ? handleGoBackEdit : handleGoBack}
                        />
                    </div>
                    <PageTitleComponent
                        title={
                            creationPlenary
                                ? intl.formatMessage({ id: NEW__PLENARY })
                                : infoPlenary !== null
                                    ? `${intl.formatMessage({ id: ORDINARY__PLENARY })} ${new Date(
                                        infoPlenary.init_date * 1000,
                                    ).toLocaleDateString()}`
                                    : ""
                        }
                    />
                </div>
                <Card className={styles.card}>
                    <CardContent>
                        {!creationPlenary && (
                            <div>
                                <TypographyComponent
                                    text={intl.formatMessage({ id: INFORMATION__PLENARY })}
                                    variant={TEXT.VARIANT_H2}
                                />
                            </div>
                        )}
                        <div className={styles.firstLine}>
                            {creationPlenary && (
                                <RoomsComponent
                                    selectedRoom={selectedRoom}
                                    setSelectedRoom={handleSetSelectedRoom}
                                    infoPlenary={infoPlenary}
                                    creationPlenary={creationPlenary}
                                />
                            )}
                            {creationPlenary && commissions?.length > 0 && (
                                <div>
                                    <TypographyComponent text={intl.formatMessage({ id: COMMISSIONS })} className={styles.typography} />
                                    <Autocomplete
                                        options={commissions}
                                        getOptionLabel={(option) => option.name}
                                        sx={{ width: 300 }}
                                        /*value={selectedCommission}*/
                                        onChange={(event, v) => handleSelectionCommission(v?.id)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={
                                                    creationPlenary
                                                        ? intl.formatMessage({ id: SELECT__COMMISSION })
                                                        : infoPlenary?.user_group_name
                                                }
                                                className={classes.auto}
                                            /*InputProps={
                                            /!*style: placeholderStyle,*!/
                                            }}*/
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            <div className={styles.languageSelect}>
                                <TypographyComponent text={intl.formatMessage({ id: LANGUAGE_REALIZATION_LABELS })} className={styles.typography} />
                                <TextField
                                    select
                                    value={selectedLanguage}
                                    onChange={handleLanguageChange}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    className={classes.auto}
                                    disabled={!isLanguageEditable}
                                >
                                    {availableLanguages.map((language, index) => (
                                        <option key={index} value={language}>
                                            {languageMap[language] || language}
                                        </option>
                                    ))}
                                </TextField>
                            </div>
                            <div className={creationPlenary ? styles.formControlLabel : styles.formControlLabelEdit}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={creationPlenary ? privateSession : infoPlenary?.private_session}
                                            onChange={() => setPrivateSession(!privateSession)}
                                        />
                                    }
                                    label={<span className={styles.formControlLabel}>{intl.formatMessage({ id: PRIVATE_SESSION })}</span>}
                                />
                            </div>
                        </div>
                        <div>
                            <TabContext value={val}>
                                <TabList centered onChange={(e, value) => handleChange(value)} aria-label="icon label tabs example">
                                    {availableLanguages?.length > 0 &&
                                        availableLanguages.map((language, index) => {
                                            return (
                                                <Tab key={index.toString()} label={renderTabLabel(language)} value={index.toString()} />);
                                        })}
                                </TabList>
                                <TabPanel value={val.toString()} className={styles.secondLine}>
                                    <div className={creationPlenary ? classes.auto : classes.edit}>
                                        <TypographyComponent
                                            text={intl.formatMessage({ id: TITLE }) + ` en ${availableLanguages[val]}`}
                                            className={styles.typography}
                                        />
                                        <TextField
                                            key={key}
                                            required={availableLanguages[val] === defaultLanguage}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className={classes.auto}
                                            value={title[availableLanguages[val]] || ""}
                                            sx={{ width: "100%" }}
                                            placeholder={
                                                creationPlenary
                                                    ? intl.formatMessage({ id: WRITE__TITLE })
                                                    : infoPlenary?.title_translations && infoPlenary?.title_translations[availableLanguages[val]]
                                                        ? infoPlenary?.title_translations[availableLanguages[val]]
                                                        : ""
                                            }
                                            onChange={(event) => handleTitleChange(availableLanguages[val], event.target.value)}
                                            InputProps={{
                                                style: placeholderStyle,
                                            }}
                                        />
                                    </div>
                                    <div className={creationPlenary ? classes.auto : classes.edit}>
                                        <TypographyComponent
                                            text={intl.formatMessage({ id: SUBTITLE }) + ` en ${availableLanguages[val]}`}
                                            className={styles.typography}
                                        />
                                        <TextField
                                            key={key}
                                            required={availableLanguages[val] === defaultLanguage}
                                            className={classes.auto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={subTitle[availableLanguages[val]] || ""}
                                            sx={{ width: "100%" }}
                                            placeholder={
                                                creationPlenary
                                                    ? intl.formatMessage({ id: WRITE__SUBTITLE })
                                                    : infoPlenary?.subtitle_translations &&
                                                        infoPlenary?.subtitle_translations[availableLanguages[val]]
                                                        ? infoPlenary?.subtitle_translations[availableLanguages[val]]
                                                        : ""
                                            }
                                            onChange={(event) => handleSubTitleChange(availableLanguages[val], event.target.value)}
                                            InputProps={{
                                                style: placeholderStyle,
                                            }}
                                        />
                                    </div>
                                    <TypographyComponent
                                        text={`${intl.formatMessage({ id: TITLE_SUBTITLE_REQUIRED_LEGEND })} (${defaultLanguage.toUpperCase()}${selectedLanguage !== defaultLanguage ? `, ${selectedLanguage.toUpperCase()}` : ""})`}
                                        variant={TEXT.VARIANT_P}
                                        className={`${styles.typographyMarginBottom} ${styles.centeredRedText}`}
                                    />
                                </TabPanel>
                            </TabContext>
                        </div>
                        <div className={styles.flexCenter}>
                            <Divider className={styles.divider} />
                        </div>
                        <div className={clsx(creationPlenary ? styles.secondLine : "")}>
                            <div className={clsx(styles.secondLine, !creationPlenary ? styles.centered : "")}>
                                <div className={clsx(styles.space, creationPlenary ? classes.auto : classes.editDate)}>
                                    <TypographyComponent text={intl.formatMessage({ id: START__DATE })} className={styles.typography} />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            id={"initDateTime"}
                                            renderInput={(props) => <TextField {...props} size="small" />}
                                            onChange={(event) => handleInitDateChange(event)}
                                            format="DD-MM-YYYY HH:mm:ss"
                                            value={dayjs(initDate)}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className={clsx(styles.space, creationPlenary ? classes.auto : classes.editDate)}>
                                    <TypographyComponent text={intl.formatMessage({ id: END__DATE })} className={styles.typography} />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            id={"endDateTime"}
                                            renderInput={(props) => <TextField {...props} size="small" />}
                                            onChange={(event) => handleEndDateChange(event)}
                                            format="DD-MM-YYYY HH:mm:ss"
                                            value={dayjs(endDate)}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            {creationPlenary && (
                                <div className={styles.space}>
                                    <TypographyComponent text={intl.formatMessage({ id: EMAIL })} className={styles.typography} />
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        className={classes.auto}
                                        value={email}
                                        sx={{ width: 600 }}
                                        onChange={(event) => handleEmailChange(event.target.value)}
                                        placeholder={intl.formatMessage({ id: WRITE__EMAIL })}
                                        InputProps={{
                                            style: placeholderStyle,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={clsx(styles.space, styles.oneInput, creationPlenary ? classes.auto : classes.editDate)}>
                            <label>
                                <TypographyComponent
                                    text={intl.formatMessage({ id: PLENARY__SESSIONS__COMMISSION_EXTERNAL__URL })}
                                    className={styles.typography}
                                />
                                <input
                                    style={{
                                        border: "1px solid grey",
                                        borderRadius: 6,
                                        padding: 8,
                                    }}
                                    type={INPUT_TYPE_TEXT}
                                    name={SESSION_INFORMATION__URL_VIDEO}
                                    value={urlExternalVideo}
                                    onChange={(event) => handleChangeURLVideo(event.target.value)}
                                />
                            </label>
                        </div>
                        {creationPlenary ? (
                            <AutomaticProductionOnCreation plenarySessionId={plenarySessionId} />
                        ) : (
                            <AutomaticProduction plenarySessionId={infoPlenary?.id} sessionData={infoPlenary} />
                        )}
                        {!creationPlenary && (
                            <div className={styles.buttonCancelEditPlenary}>
                                <ButtonComponent
                                    text={intl.formatMessage({ id: COMMON_CANCEL })}
                                    variant={BUTTON.VARIANT_SECONDARY}
                                    icon={CANCEL_ICON}
                                    onClick={handleGoBackEdit}
                                />
                                <ButtonComponent
                                    text={intl.formatMessage({ id: COMMON_SAVE })}
                                    variant={BUTTON.VARIANT_PRIMARY}
                                    icon={CHECK_ICON}
                                    onClick={handleEditPlenary}
                                />
                            </div>
                        )}
                        {creationPlenary && (
                            <div className={styles.buttonCreatePlenary}>
                                <ButtonComponent
                                    text={intl.formatMessage({ id: ADD__PLENARY })}
                                    variant={BUTTON.VARIANT__PRIMARY_LIMIT_WITH}
                                    icon={SEND_ICON_WHITE}
                                    onClick={handleCreatePlenary}
                                />
                            </div>
                        )}
                    </CardContent>
                </Card>
            </div>
            <Notification message={formError} clearMessage={() => setFormError('')} />
        </>
    );
};

export default withAuthorization(ROLES_ALL)(PlenaryCreationComponent);
