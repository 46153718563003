import React, { useEffect, useState } from "react";
import MinutesManagerComponent from "../minutesManagerComponent/MinutesManagerComponent";
import { createMinuteService, getSessionPeople } from "../../../../../../../services/commonservices";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SaveIcon from "@mui/icons-material/Save";
import { SESSION_MINUTES_FROM } from "../../../../../../../utils/constants";
import TypographyComponent from "../../../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_H2, TEXT_VARIANT_P } from "../../../../../../shared/typography/constants";
import ButtonComponent from "../../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../../shared/button/buttonConstants";
import { useIntl } from "react-intl";
import {
  COMMON_CANCEL,
  COMMON_SAVE,
  MINUTES_MANAGER__INTERVENTION,
  PLENARY_NEW_INTERVENTION,
} from "../../../../../../../translations/constans";
import { convertTimeRounded } from "../../../../../../../utils/utils";
import style from "./CreateIntervention.module.css";

const CreateIntervention = ({
  agendaId,
  plenarySessionId,
  videoRef,
  closeCreation,
  cancelCreation,
  reloadInterventionsAndMinutes,
  setReloadInterventionsAndMinutes,
}) => {
  const intl = useIntl();
  const [timingFrom, setTimingFrom] = useState(0);
  const [timingTo, setTimingTo] = useState(0);
  const [people, setPeople] = useState([]);
  const [peopleSelected, setPeopleSelected] = useState("");

  const handleTimeFrom = (time) => {
    setTimingFrom(time);
  };
  const handleTimingTo = (time) => {
    setTimingTo(time);
  };
  const handlePeople = (people) => {
    setPeople(people);
  };
  const handlePeopleSelected = (event) => {
    setPeopleSelected(event.target.value);
  };
  const getSessionPeopleSuccess = (response) => {
    handlePeople(response.data);
  };

  useEffect(() => {
    getSessionPeople(plenarySessionId, getSessionPeopleSuccess);
    if (reloadInterventionsAndMinutes === false) {
      setReloadInterventionsAndMinutes(true);
    }
  }, [reloadInterventionsAndMinutes]);

  const handleChangeTime = (time, timer) => {
    if (timer === SESSION_MINUTES_FROM) {
      setTimingFrom(time);
    } else {
      setTimingTo(time);
    }
  };

  const handleChangeTimeCreateIntervention = (time, timer) => {
    if (timer === SESSION_MINUTES_FROM) {
      setTimingFrom(time);
    } else {
      setTimingTo(time);
    }
  };
  const handleCloseCreation = () => {
    setPeopleSelected(0);
    setTimingFrom(0);
    setTimingTo(0);
    closeCreation(null);
  };
  const handelCancelCreation = () => {
    cancelCreation();
  };
  const handleSaveMinute = () => {
    const body = {
      agenda_point_id: agendaId,
      participant_id: peopleSelected,
      relative_end_time: timingTo,
      relative_init_time: timingFrom,
    };
    createMinuteService(plenarySessionId, body, handleCloseCreation);
  };

  return (
    <div className={style.createInterventionForm}>
      <TypographyComponent text={intl.formatMessage({ id: PLENARY_NEW_INTERVENTION })} variant={TEXT_VARIANT_H2} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "var(--spacing-xl)",
          padding: "0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: "200px",
            height: "fit-content",
          }}
        >
          <label
            style={{
              border: "1px solid grey",
              borderRadius: 8,
              padding: 8,
              width: "100%",
            }}
          >
            <TypographyComponent
              text={intl.formatMessage({ id: MINUTES_MANAGER__INTERVENTION })}
              variant={TEXT_VARIANT_P}
              style={{ borderBottom: "1px solid grey" }}
            />

            <select value={peopleSelected} onChange={handlePeopleSelected}>
              <option value="">Nuevo participante</option>
              {people.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
        <div>
          <MinutesManagerComponent
            isCreating
            isIntervention
            timingFrom={timingFrom}
            timingTo={timingTo}
            videoRef={videoRef}
            handleSave={handleChangeTimeCreateIntervention}
            onTimeChange={handleChangeTime}
            editableByStatus={true}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "var(--spacing-md)" }}>
          <ButtonComponent
            disabled={!peopleSelected}
            onClick={handleSaveMinute}
            variant={BUTTON.VARIANT_SECONDARY}
            icon={<SaveIcon />}
            text={intl.formatMessage({ id: COMMON_SAVE })}
          />
          <ButtonComponent
            disabled={!peopleSelected}
            onClick={handelCancelCreation}
            variant={BUTTON.VARIANT_DANGER}
            icon={<HighlightOffIcon />}
            text={intl.formatMessage({ id: COMMON_CANCEL })}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateIntervention;
