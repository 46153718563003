import React from "react";

const SEND_ICON = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.16665 5.26667L13.2417 2.90834C16.4167 1.85001 18.1417 3.58334 17.0917 6.75834L14.7333 13.8333C13.15 18.5917 10.55 18.5917 8.96665 13.8333L8.26665 11.7333L6.16665 11.0333C1.40832 9.45001 1.40832 6.85834 6.16665 5.26667Z"
      stroke="#e30613"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.42505 11.3751L11.4084 8.38342"
      stroke="#e30613"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SEND_ICON;
