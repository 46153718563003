const styles = () => ({
  root: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.8)",
  },
  disable: {
    display: "none",
  },
  imageContainer: {
    transitionProperty: "opacity, left, top, height",
    transitionDuration: "3s, 5s",
    position: "absolute",
    top: "40%",
    left: "45%",
  },
  image: {
    animation: "rotation 3s infinite linear",
  },
  description: {
    color: "white",
    marginTop: 34,
    fontSize: 24,
  },
});

export default styles;
