import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Paper, Typography } from "@mui/material";
import { votingService } from "../../../../../../../../../../../services/commonservices";
import ButtonComponent from "../../../../../../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../../../../../../shared/button/buttonConstants";
import { useIntl } from "react-intl";
import style from "./VotingModal.module.css";
import { PLENARY_VOTING__RESULT__BUTTON } from "../../../../../../../../../../../translations/constans";

export default function VotingModalComponent({
  activeAgendaItemId,
  activeAgendaItemTitle,
  votingMinute,
  plenarySessionId,
  voteYes = 0,
  voteNo = 0,
  voteAbstention = 0,
  callBack = () => {},
}) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [votes, setVotes] = useState({
    yes: +voteYes,
    no: +voteNo,
    abstention: +voteAbstention,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVoteChange = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0;
      return;
    }
    setVotes({
      ...votes,
      [e.target.name]: parseFloat(+e.target.value),
    });
  };

  const callBakChangeData = () => {
    callBack();
    handleClose();
  };

  const handleSubmit = async () => {
    votingService(plenarySessionId, votingMinute, votes, callBakChangeData);
  };

  return (
    <>
      <div className={style.buttonContainer}>
        <ButtonComponent
          color="primary"
          disabled={activeAgendaItemId === null}
          variant={BUTTON.VARIANT_SECONDARY}
          onClick={handleClickOpen}
          size={BUTTON.SIZE__FIT_CONTENT}
          text={intl.formatMessage({ id: PLENARY_VOTING__RESULT__BUTTON })}
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Votación</DialogTitle>
        <DialogContent>
          <Typography>{`Votación para: ${activeAgendaItemTitle}`}</Typography>
          <TextField
            margin="dense"
            label="Votos a favor"
            type="number"
            fullWidth
            variant="outlined"
            name="yes"
            value={votes.yes}
            onChange={handleVoteChange}
          />
          <TextField
            margin="dense"
            label="Votos en contra"
            type="number"
            fullWidth
            variant="outlined"
            name="no"
            value={votes.no}
            onChange={handleVoteChange}
          />
          <TextField
            margin="dense"
            label="Abstenciones"
            type="number"
            fullWidth
            variant="outlined"
            name="abstention"
            value={votes.abstention}
            onChange={handleVoteChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
