import React from "react";
import PropTypes from "prop-types";
import {
  DEFAULT_TEXT,
  TEXT_VARIANT_H1,
  TEXT_VARIANT_H2,
  TEXT_VARIANT_H3,
  TEXT_VARIANT_H4,
  TEXT_VARIANT_P,
  TEXT_VARIANT_SMALL,
  TEXT_VARIANT_SPAN,
  TEXT_VARIANT_STRONG,
} from "./constants";

function Typography({ idText = DEFAULT_TEXT, variant = TEXT_VARIANT_P }) {
  return React.createElement(variant, null, idText.toString());
}

export default Typography;
Typography.propTypes = {
  idText: PropTypes.string,
  variant: PropTypes.oneOf([
    TEXT_VARIANT_H1,
    TEXT_VARIANT_H2,
    TEXT_VARIANT_H3,
    TEXT_VARIANT_H4,
    TEXT_VARIANT_P,
    TEXT_VARIANT_SPAN,
    TEXT_VARIANT_SMALL,
    TEXT_VARIANT_STRONG,
  ]),
};
