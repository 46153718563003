import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import AlertDialog from "../AlertDialog";
import {
  ERROR_BOUNDARY__DESCRIPTION,
  ERROR_BOUNDARY__TITLE,
  COMMON__GLOBAL_BUTTONS__CLOSE,
} from "../../../translations/constans";
import LANDING_ERROR_BOUNDARY__KEY from "./constants";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, open: null };
  }

  static getDerivedStateFromError(error) {
    return { error: error.toString(), open: true };
  }

  onClose = () => this.setState({ open: false });

  render() {
    const { error, open } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div key={LANDING_ERROR_BOUNDARY__KEY}>
          <AlertDialog
            open={open}
            title={<FormattedMessage id={ERROR_BOUNDARY__TITLE} />}
            description={<FormattedMessage id={ERROR_BOUNDARY__DESCRIPTION} />}
            onClose={this.onClose}
            buttonCloseText={<FormattedMessage id={COMMON__GLOBAL_BUTTONS__CLOSE} />}
          />
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};
