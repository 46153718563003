import React from "react";
import FilterPlenaryListComponent from "./components/filterPlenaryListComponent/FilterPlenaryListComponent";
import styles from "./PlenaryList.module.css";

const PlenaryListComponent = () => {
  return (
    <div className={styles.plenaryListPageContainer}>
      <FilterPlenaryListComponent styles={styles} />
    </div>
  );
};

export default PlenaryListComponent;
