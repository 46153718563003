import React from "react";
import PropTypes from "prop-types";
import style from "./PageContainer.module.css";

const PageContainer = ({ component }) => {
  return <div className={style.rootPage}>{component}</div>;
};

PageContainer.propTypes = {
  component: PropTypes.element.isRequired,
};

export default PageContainer;
