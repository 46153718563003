import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  BUTTON_DISABLED_BY_SESSION_STATUS,
  COMMON_SAVE,
  PLENARY__SESSIONS__CHANGE_START__OFFSET__TITLE,
} from "../../../../../../../../translations/constans";
import { updateInitOffsetService } from "../../../../../../../../services/commonservices";
import TypographyComponent from "../../../../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_P, TEXT_VARIANT_SMALL } from "../../../../../../../shared/typography/constants";
import ButtonComponent from "../../../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../../../shared/button/buttonConstants";
import style from "./OffsetInitTime.module.css";
import { DisabledByDefault } from "@mui/icons-material";
import {
  SESSION__PLENARY__STATUS__PUBLISHED,
  SESSION__PLENARY__STATUS__REVIEWED,
} from "../../../../../../../../utils/constants";
import Tooltip from "@mui/material/Tooltip";

const OffsetInitTimeComponent = ({
  plenarySessionId,
  onReloadData,
  editableByStatus,
  buttonDisabled,
  statusReviewedPublished,
}) => {
  const [time, setTime] = useState("");

  const intl = useIntl();

  const handleChangeTime = (event) => {
    setTime(event.target.value);
  };

  const handleSendOffset = (event) => {
    updateInitOffsetService(plenarySessionId, time * 1000, onReloadData);
  };
  return (
    <div className={style.offsetInitTimeContainer}>
      <TypographyComponent
        text={intl.formatMessage({ id: PLENARY__SESSIONS__CHANGE_START__OFFSET__TITLE })}
        variant={TEXT_VARIANT_P}
      />
      <Tooltip title={statusReviewedPublished ? intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS }) : ""}>
        <div className={style.offsetInitTooltip}>
          <input
            className={style.offsetInitInput}
            type="number"
            value={time}
            onChange={handleChangeTime}
            disabled={statusReviewedPublished}
          />
          <ButtonComponent
            onClick={handleSendOffset}
            text={intl.formatMessage({ id: COMMON_SAVE })}
            disabled={!editableByStatus}
            variant={buttonDisabled}
            size={BUTTON.SIZE__FIT_CONTENT}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default OffsetInitTimeComponent;
