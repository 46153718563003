import React from "react";
import PropTypes from "prop-types";

import TypographyComponent from "../typography/TypographyComponent";
import { TEXT } from "../typography/typographyConstants";

const PageTitleComponent = ({ title }) => {
  return (
    <>
      <TypographyComponent text={title} variant={TEXT.VARIANT_H1} />
    </>
  );
};

PageTitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageTitleComponent;
