import React, { useState, useEffect } from "react";
import { getDocumentPurposes, getPlenarySessionFiles, addSessionDocument, updateSessionDocument } from "../../../../../../services/commonservices";
import { useIntl } from "react-intl";
import {
    COMMON_CANCEL,
    COMMON_SAVE,
    PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__DESCRIPTION,
    PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__FILE_TYPE,
    PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__DOCUMENT_NAME,
    PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__PURPOSE,
    PLENARY__SESSIONS__DOCUMENTS__UPLOAD,
    PLENARY__SESSIONS__DOCUMENTS__ERROR__MAX_SIZE_EXCEEDED,
} from "../../../../../../translations/constans";
import ButtonComponent from "../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../shared/button/buttonConstants";
import * as Yup from "yup";
import Notification from "../../../../../common/Notification";

const fileUploadStyles = {
    input: {
        border: "1px solid grey",
        marginTop: 10,
        borderRadius: 6,
        padding: 8,
    },
    formContainer: {
        display: "grid",
        height: "100%",
        gap: "10px",
    },
    buttonContainer: {
        marginTop: "2rem",
        display: "flex",
        justifyContent: "space-around",
        padding: 1,
    },
    acceptButton: {
        marginLeft: 5,
    },
    cancelButton: {
        marginRight: 10,
    },
    textArea: {
        border: "1px solid grey",
        marginTop: 10,
        borderRadius: 6,
        padding: 10,
        height: "8rem",
    },
    label: {
        marginTop: 10,
        fontWeight: 'bold',
    }
};

const removeFileExtension = (fileName) => {
    return fileName.replace(/\.[^/.]+$/, "");
};

const getPurposeId = (purposes, codename) => {
    const purpose = purposes.find((purpose) => purpose.codename === codename);
    return purpose ? purpose.id : null;
}

const DocumentUploadComponent = ({
    isCreatedAndScheduled,
    plenarySessionId,
    onUploadSuccess,
    setCreateFormOpened,
    editingDocument,
}) => {
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(editingDocument ? removeFileExtension(editingDocument.file_name) : "");
    const [fileType, setFileType] = useState(editingDocument ? editingDocument.file_type : "");
    const [description, setDescription] = useState(editingDocument ? editingDocument.description : "");
    const [purposes, setPurposes] = useState([]);
    const [filePurpose, setFilePurpose] = useState(null);
    const [formError, setFormError] = useState("");

    const intl = useIntl();

    useEffect(() => {
        getDocumentPurposes(
            (data) => {
                setPurposes(data);
                setFilePurpose(editingDocument?.purpose?.codename ? getPurposeId(data, editingDocument.purpose.codename) : "");
            }
        );
    }, []);

    useEffect(() => {
        if (editingDocument) {
            setFilePurpose(editingDocument?.purpose?.codename ? getPurposeId(purposes, editingDocument.purpose.codename) : "");
        }
    }, [editingDocument]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSizeInBytes = 100 * 1024 * 1024;

        if (file.size > maxSizeInBytes) {
            setFormError(PLENARY__SESSIONS__DOCUMENTS__ERROR__MAX_SIZE_EXCEEDED);
            event.target.value = null;
        } else {
            setFile(file);
            const fileType = file.name.split('.').pop();
            setFileType(fileType);
        }
    };

    const renderFormError = (error) => {
        if (error.path === "file" && error.type === "required") {
            return "form_message_document_file";
        } else if (error.path === "file") {
            return "form_message_document_type_admited";
        }
        if (error.path === "file_name" && error.type === "required") {
            return "form_message_document_title";
        }
        if (error.path === "file_type") {
            return "form_message_document_type";
        }
        if (error.path === "description" && error.type === "required") {
            return "form_message_document_description";
        }
        if (error.path === "file_purpose" && error.type === "required") {
            return "form_message_document_purpose";
        }
    };

    const validationSchemaUpload = Yup.object().shape({
        file: Yup.mixed().test("fileType", "El archivo debe ser de tipo texto, imagen, PDF o DOC", (value, { path }) => {
            if (!editingDocument && !value) return false; // Si no hay archivo y no es edición, la validación falla
            const allowedMimeTypes = [
                "text/plain",
                "image/jpeg",
                "image/png",
                "image/gif",
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ];
            if (value && !allowedMimeTypes.includes(value.type)) {
                return new Yup.ValidationError("El archivo debe ser de tipo texto, imagen, PDF o DOC", "", path);
            }
            return true;
        }),
        file_name: Yup.string().required(),
        description: Yup.string().required(),
        file_purpose: Yup.string().required(),
    });

    const onUploadError = () => {
        setFormError(PLENARY__SESSIONS__DOCUMENTS__UPLOAD);
    };

    const handleUpload = () => {
        const formData = new FormData();
        if (file) formData.append("file", file);

        // Remove the file extension from the file name
        const cleanedFileName = removeFileExtension(fileName);

        formData.append("file_name", cleanedFileName);
        formData.append("file_type", fileType);
        formData.append("description", description);
        formData.append("file_purpose", filePurpose);

        const plainFormData = {};
        for (const [key, value] of formData.entries()) {
            plainFormData[key] = value;
        }

        validationSchemaUpload
            .validate(plainFormData)
            .then(() => {
                if (editingDocument) {
                    const updateData = {
                        file_name: cleanedFileName,
                        description,
                        purpose_id: filePurpose,
                    };
                    updateSessionDocument(plenarySessionId, editingDocument.id, updateData, onUploadSuccess, setFormError);
                } else {
                    addSessionDocument(formData, plenarySessionId, onUploadSuccess, onUploadError);
                }
            })
            .catch((error) => {
                const formattedError = renderFormError(error);
                setFormError("");
                setFormError(formattedError);
            });
    };

    return (
        <>
            <div style={fileUploadStyles.formContainer}>
                {!editingDocument && (
                    <>
                        <input
                            style={fileUploadStyles.input}
                            type="file"
                            onChange={handleFileChange}
                            disabled={!!editingDocument}
                            accept=".txt,.doc,.docx,.pdf,.jpg,.jpeg,.png"
                        />
                        <label style={fileUploadStyles.label}>
                            {intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__FILE_TYPE })}
                        </label>
                        <input
                            style={fileUploadStyles.input}
                            type="text"
                            placeholder={intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__FILE_TYPE })}
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value)}
                        />
                    </>
                )}
                <label style={fileUploadStyles.label}>
                    {intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__DOCUMENT_NAME })}
                </label>
                <input
                    style={fileUploadStyles.input}
                    type="text"
                    placeholder={intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__DOCUMENT_NAME })}
                    value={fileName}
                    onChange={(e) => setFileName(removeFileExtension(e.target.value))}
                />
                <label style={fileUploadStyles.label}>
                    {intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__DESCRIPTION })}
                </label>
                <textarea
                    placeholder={intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__DESCRIPTION })}
                    style={fileUploadStyles.textArea}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                <label style={fileUploadStyles.label}>
                    {intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__PURPOSE })}
                </label>
                <select
                    style={fileUploadStyles.input}
                    value={filePurpose}
                    onChange={(e) => setFilePurpose(e.target.value)}
                    required
                >
                    <option value="" disabled>{intl.formatMessage({ id: PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__PURPOSE })}</option>
                    {purposes.map((purpose) => (
                        <option key={purpose.id} value={String(purpose.id)}>
                            {purpose.name_translations[intl.locale] || purpose.name_translations["en"]}
                        </option>
                    ))}
                </select>
                <div style={fileUploadStyles.buttonContainer}>
                    <div style={fileUploadStyles.cancelButton}>
                        <ButtonComponent
                            text={intl.formatMessage({ id: COMMON_CANCEL })}
                            variant={BUTTON.VARIANT_SECONDARY}
                            onClick={() => setCreateFormOpened((prev) => !prev)}
                        />
                    </div>
                    <div style={fileUploadStyles.acceptButton}>
                        <ButtonComponent
                            text={intl.formatMessage({ id: COMMON_SAVE })}
                            variant={BUTTON.VARIANT_PRIMARY}
                            onClick={handleUpload}
                        />
                    </div>
                </div>
            </div>
            <Notification message={formError} clearMessage={() => setFormError('')}/>
        </>
    );
};

export default DocumentUploadComponent;
