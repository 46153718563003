import React, { useEffect, useState } from "react";
import PlenaryCreationComponent from "../../common/plenaryCreationComponent/PlenaryCreationComponent";
import withAuthorization from "../../common/auth/withAuthorization";
import { ROLES_ALL } from "../../../utils/constants";
import { getSessionInfo } from "../../../services/commonservices";
import { useParams } from "react-router-dom";
const PlenaryComponent = ({ creationPlenary }) => {
  const [infoPlenary, setInfoPlenary] = useState(null);
  let { id } = useParams();

  const plenaryInfoCallback = (r) => {
    setInfoPlenary(r.data);
  };
  const getPlenaryInfo = () => {
    getSessionInfo(id, plenaryInfoCallback);
  };

  useEffect(() => {
    if (creationPlenary === false && !infoPlenary?.length) {
      getPlenaryInfo(id);
    }
  }, []);
  return <PlenaryCreationComponent creationPlenary={creationPlenary} infoPlenary={infoPlenary} />;
};

export default withAuthorization(ROLES_ALL)(PlenaryComponent);
