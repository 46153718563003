import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";
import style from "../../PlenaryListTable.module.css";
import Typography from "../../../../../../../shared/typography/Typography";
import TypographyComponent from "../../../../../../../shared/typography/TypographyComponent";
import clsx from "clsx";
import { formatDateTime } from "../../../../../../../../utils/utils";
import {
  APP_TENANT,
  SESSION__PLENARY__STATUS__SCHEDULED,
  SESSION__PLENARY__STATUS__STARTED,
  SESSION__PLENARY__STATUS__STARTING,
} from "../../../../../../../../utils/constants";
import { useIntl } from "react-intl";
import { plenaryStatusTranslation } from "../../../../../plenaryListConstants";
import ButtonComponent from "../../../../../../../shared/button/ButtonComponent";
import PlenaryListModalComponent from "./components/plenaryListModalComponent/PlenaryListModalComponent";
import { BUTTON } from "../../../../../../../shared/button/buttonConstants";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory, Redirect } from "react-router-dom";

const RowComponent = ({ row, columns, onReloadData }) => {
  const intl = useIntl();
  const history = useHistory();
  const [id, setId] = useState(null);
  const initDate = row["init_date"] ? formatDateTime(row["init_date"]) : null;
  const endDate = row["end_date"] ? formatDateTime(row["end_date"]) : null;
  const statusColorCode = {
    [SESSION__PLENARY__STATUS__SCHEDULED]: "scheduled",
    [SESSION__PLENARY__STATUS__STARTED]: "started",
    [SESSION__PLENARY__STATUS__STARTING]: "starting",
  };
  const statusChipColorCode = statusColorCode[row["status"]];
  const handleRedirect = (newId) => {
    setId(newId);
  };

  const handleOpenInNewTab = (newId) => {
    const url = `/plenos/${APP_TENANT}/${newId}`;
    window.open(url, "_blank");
  };

  if (id) {
    return <Redirect to={`/${APP_TENANT}/${id}`} />;
  }

  return (
    <TableRow className={style.row}>
      {columns?.map(({ field }) => {
        return field === "status" ? (
          <TableCell key={field} component="th" scope="row" className={style.tableCell}>
            <TypographyComponent
              text={plenaryStatusTranslation(intl, row[field]) || " - "}
              variant={"p"}
              className={clsx(style.statusColumnText, style[`statusColumnColorText-${statusChipColorCode}`])}
            />
          </TableCell>
        ) : field === "title" ? (
          <TableCell key={field} component="th" scope="row" className={style.tableCell}>
            <ButtonComponent
              onClick={() => handleRedirect(row.id)}
              variant={BUTTON.VARIANT_TEXT}
              text={row[field]}
              size={BUTTON.SIZE__FIT_CONTENT}
            />
          </TableCell>
        ) : field === "init_date" ? (
          <TableCell key={field} component="th" scope="row" className={style.tableCell}>
            <Typography idText={initDate || " - "} variant={"p"} />
          </TableCell>
        ) : field === "end_date" ? (
          <TableCell key={field} component="th" scope="row" className={style.tableCell}>
            <Typography idText={endDate || " - "} variant={"p"} />
          </TableCell>
        ) : (
          <TableCell key={field} component="th" scope="row" className={style.tableCell}>
            <Typography idText={row[field] || " - "} variant={"p"} />
          </TableCell>
        );
      })}
      <TableCell key={"field"} component="th" scope="row" className={style.tableCell}>
      <div className={style.actionCell}>
          <PlenaryListModalComponent plenarySessionId={row?.id} onReloadData={onReloadData} />
        <OpenInNewIcon onClick={() => handleOpenInNewTab(row.id)} className={style.openInNewIcon} />
        </div>
      </TableCell>
    </TableRow>
  );
};

RowComponent.propTypes = {
  row: PropTypes.shape({
    // name: PropTypes.string.isRequired,
    // description: PropTypes.string.isRequired,
    // severity: PropTypes.string.isRequired,
  }).isRequired,
};

export default RowComponent;
