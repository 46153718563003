import React, { useState, useEffect } from "react";
import { Modal, Box, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import { createAgendaPointService, updateAgendaPointService, getSessionInfo } from "../../../../../../../services/commonservices";
import TypographyComponent from "../../../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_H2, TEXT_VARIANT_P } from "../../../../../../shared/typography/constants";
import {
    COMMON_CANCEL,
    COMMON_SAVE,
    MESSAGE_VALIDATION_DESCRIPTION_FIELD,
    SESSION_AGENDA__ADD_ITEM,
    TITLE_SUBTITLE_REQUIRED_LEGEND,
    MESSAGE_VALIDATION_NAME_REALIZATION
} from "../../../../../../../translations/constans";
import ButtonComponent from "../../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../../shared/button/buttonConstants";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import styles from "./CreateOrderModal.module.css";
import { LOCAL_STORAGE_ITEMS } from "../../../../../../../utils/constants";

const style = {
    modalContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        borderRadius: "var(--border-radius)",
        p: 4,
    },
};

function CreateOrderModalComponent({
    availableLanguages,
    open,
    plenarySessionId,
    onClose,
    onReloadData,
    itemEdit,
    onCloseEdit,
    handleSetReloadPointsAndMinutes,
}) {
    const intl = useIntl();
    const [descriptions, setDescriptions] = useState({ ...itemEdit?.item_description_translations });
    const [errors, setErrors] = useState({});
    const default_language = localStorage.getItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_DEFAULT_LANGUAGE);
    const [realizationLanguage, setRealizationLanguage] = useState("");

    const [formData, setFormData] = useState({
        descriptionFields: availableLanguages.reduce(
            (acc, lang) => ({
                ...acc,
                [lang]: "",
            }),
            {},
        ),
    });
    useEffect(() => {
        if (plenarySessionId) {
            getSessionInfo(plenarySessionId, (response) => {
                setRealizationLanguage(response.data.language);
            }, (error) => {
                console.error("Error fetching plenary session info:", error);
            });
        }
    }, [plenarySessionId]);

    const validateDescriptionsFields = () => {
        if (Object.hasOwnProperty.call(descriptions, default_language)) {
            if (descriptions[default_language] !== "") {
                return true;
            }
        }
        return false;
    };
    const validateDescriptionsForRealization = () => {
        if (Object.hasOwnProperty.call(descriptions, realizationLanguage)) {
            if (descriptions[realizationLanguage] !== "") {
                return true;
            }
        }
        return false;
    };

    const validateField = (name, value) => {
        if (name === default_language) {
            if (!validateDescriptionsFields()) {
                return intl.formatMessage({ id: MESSAGE_VALIDATION_DESCRIPTION_FIELD });
            }
        }

        if (name === realizationLanguage) {
            if (!validateDescriptionsForRealization()) {
                return intl.formatMessage({ id: MESSAGE_VALIDATION_NAME_REALIZATION });
            }
        }

        return "";
    };
    const handleClose = () => {
        onCloseEdit();
        onClose(false);
    };

    const handleDescriptionChange = (description, language) => {
        const newDescriptions = { ...descriptions };
        newDescriptions[language] = description;
        setDescriptions(newDescriptions);

        setFormData({
            ...formData,
            descriptionFields: {
                ...formData.descriptionFields,
                [language]: description,
            },
        });
    };
    const handleCreateOrderSuccess = () => {
        onCloseEdit();
        handleSetReloadPointsAndMinutes();
        onClose();
    };
    const handleCreateOrder = () => {
        const body = {
            item_description_translations: descriptions,
        };
        createAgendaPointService(plenarySessionId, body, handleCreateOrderSuccess);
    };
    const handleEditOrder = (pointId) => {
        const body = {
            item_description_translations: descriptions,
        };
        updateAgendaPointService(pointId, plenarySessionId, body, handleCreateOrderSuccess);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let newErrors = {};
        let isValid = true;

        Object.keys(formData.descriptionFields).forEach((key) => {
            const error = validateField(key, formData.descriptionFields[key]);
            if (error) isValid = false;
            newErrors[key] = error;
        });

        setErrors(newErrors);

        if (isValid) {
            if (itemEdit) {
                handleEditOrder(itemEdit.id);
            } else {
                handleCreateOrder();
            }
        }
    };
    const renderLanguageLabel = (language) => {
        const isDefaultLanguage = language === default_language;
        const isRealizationLanguage = language === realizationLanguage;

        return (
            <span>
                {intl.formatMessage({ id: `common__int${language.toUpperCase()}` })}
                {(isDefaultLanguage || isRealizationLanguage) && <span style={{ color: 'red' }}> *</span>}
            </span>
        );
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style.modalContainer}>
                    <TypographyComponent
                        text={intl.formatMessage({ id: SESSION_AGENDA__ADD_ITEM })}
                        variant={TEXT_VARIANT_H2}
                        className={styles.typographyMarginBottom}
                    />
                    {availableLanguages.map((availableLanguage, index) => (
                        <div key={index}>
                            {renderLanguageLabel(availableLanguage)}
                            <TextField
                                type="text"
                                style={{ border: "1px solid gray" }}
                                name={`${availableLanguage}`}
                                error={!!errors[availableLanguage]}
                                helperText={errors[availableLanguage]}
                                value={descriptions[availableLanguage]}
                                onChange={(event) => handleDescriptionChange(event.target.value, availableLanguage)}
                            />
                        </div>
                    ))}

                    <TypographyComponent
                        text={`${intl.formatMessage({ id: TITLE_SUBTITLE_REQUIRED_LEGEND })} (${default_language.toUpperCase()}${realizationLanguage !== default_language ? `, ${realizationLanguage.toUpperCase()}` : ""})`}
                        variant={TEXT_VARIANT_P}
                        className={`${styles.typographyMarginBottom} ${styles.centeredRedText}`}
                    />

                    <div className={styles.buttonsContainer}>
                        <ButtonComponent
                            onClick={handleClose}
                            variant={BUTTON.VARIANT_SECONDARY}
                            text={intl.formatMessage({ id: COMMON_CANCEL })}
                            icon={<CancelOutlined />}
                            size={BUTTON.SIZE__FIT_CONTENT}
                        />
                        <ButtonComponent
                            onClick={handleSubmit}
                            variant={BUTTON.VARIANT_PRIMARY}
                            text={intl.formatMessage({ id: COMMON_SAVE })}
                            icon={<CheckCircleOutline />}
                            size={BUTTON.SIZE__FIT_CONTENT}
                        />
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default CreateOrderModalComponent;
