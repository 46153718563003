import React from "react";
import { useSelector } from "react-redux";

import LoadingComponent from "./LoadingComponent";

const GlobalLoadingComponent = () => {
  const commonStateLoading = useSelector((state) => state.common.loading);
  return <LoadingComponent open={commonStateLoading} description />;
};

export default GlobalLoadingComponent;
