import React from "react";
import styles from "./Button.module.css";
import PropTypes from "prop-types";
import { BUTTON } from "./buttonConstants";

function ButtonComponent({
  disabled = false,
  onClick = () => {},
  variant = BUTTON.VARIANT_PRIMARY,
  haveText = true,
  text = haveText ? BUTTON.DEFAULT_TEXT : null,
  type = BUTTON.TYPE_BUTTON,
  size,
  icon,
  style,
}) {
  // Determine the appropriate class for the icon based on the variant
  const iconClass = variant === BUTTON.VARIANT_DISABLE ? styles.iconDisable : styles.icon;

  return (
    <button
      disabled={disabled}
      className={`${styles.button} ${styles[variant]} ${styles[size]}`}
      data-tema-oscuro={false}
      onClick={onClick}
      type={type}
      style={style}
    >
      <span className={iconClass}>{icon}</span> {text}
    </button>
  );
}

export default ButtonComponent;

ButtonComponent.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.oneOf([BUTTON.TYPE_BUTTON, BUTTON.TYPE_SUBMIT, BUTTON.TYPE_RESET]),
  size: PropTypes.oneOf([BUTTON.SIZE__FIT_CONTENT]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    BUTTON.VARIANT_TEXT,
    BUTTON.VARIANT_PRIMARY,
    BUTTON.VARIANT_SECONDARY,
    BUTTON.VARIANT_SUCCESS,
    BUTTON.VARIANT_DANGER,
    BUTTON.VARIANT_WARNING,
    BUTTON.VARIANT_LIGHT,
    BUTTON.VARIANT_DARK,
    BUTTON.VARIANT__PRIMARY_LIMIT_WITH,
    BUTTON.VARIANT_DISABLE,
    BUTTON.VARIANT_TEXT_DISABLE,
  ]),
};
