import { getRooms } from "../../../services/commonservices";
import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@mui/material";
import TypographyComponent from "../../shared/typography/TypographyComponent";
import {
  PLENARY_LIST__FILTER_SELECT_PLACE,
  PLENARY_LIST__FILTER_SELECT_STATUS_ALL,
  SELECT__ROOM,
} from "../../../translations/constans";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import style from "./RoomComponent.module.css";
import { TEXT_VARIANT_P } from "../../shared/typography/constants";

const useStyles = makeStyles(() => ({
  auto: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: "5px",
    },
    "& .MuiAutocomplete-inputRoot": {
      fontFamily: "var(--font-family-titles) !important",
    },
  },
}));
const RoomsComponent = ({ selectedRoom, setSelectedRoom, infoPlenary = null, creationPlenary }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [rooms, setRooms] = useState([]);
  const roomsCallback = (r) => {
    if (r.data.length === 1) {
      setSelectedRoom(r.data[0].id);
    }
    setRooms(r.data);
  };
  const getRoom = () => {
    getRooms(roomsCallback);
  };

  useEffect(() => {
    !rooms?.length && getRoom();
  }, []);

  const handleSelectionRooms = (id) => {
    setSelectedRoom(id);
  };

  useEffect(() => {
    rooms?.length === 1 && handleSelectionRooms(rooms[0]?.id);
  }, [rooms]);

  return (
    <div>
      {creationPlenary && (
        <TypographyComponent
          text={intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_PLACE })}
          variant={TEXT_VARIANT_P}
        />
      )}
      {rooms?.length === 1 ? (
        <div className={style.textRoomField}>{rooms[0].name}</div>
      ) : (
        <div>
          <Autocomplete
            options={rooms}
            getOptionLabel={(option) => option.name}
            onChange={(event, v) => handleSelectionRooms(v?.id)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  creationPlenary
                    ? intl.formatMessage({ id: SELECT__ROOM })
                    : intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_STATUS_ALL })
                }
                className={classes.auto}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default RoomsComponent;
