import React from "react";

import { useIntl } from "react-intl";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

import { LOCAL_STORAGE_ITEMS } from "../../../../../utils/constants";
import FlagIcon from "../../../FlagIconComponent";
import styles from "./languageMenuComponent.module.css";

const LanguageMenuComponent = ({ anchor, handleClose, handleLanguageChanged, i18nConfig, availableLanguages }) => {
  const intl = useIntl();

  const changeLanguage = (event, language) => {
    localStorage.setItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_LOCALE, language);
    handleLanguageChanged(language);
    handleClose();
  };

  return (
    <Menu id="language-menu" anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={handleClose}>
      <div>
        {" "}
        {/* Included as anchor for the menu */}
        {availableLanguages.map((language) => (
          <MenuItem key={language} onClick={(event) => changeLanguage(event, language)}>
            <div className={styles.languages}>
              <div>
                <ListItemIcon>
                  <FlagIcon flag={language} />
                </ListItemIcon>
              </div>
              <div>
                <ListItemText primary={intl.formatMessage({ id: i18nConfig[language].label })} />
              </div>
            </div>
          </MenuItem>
        ))}
      </div>
    </Menu>
  );
};

LanguageMenuComponent.propTypes = {
  anchor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(Element), // Si `anchor` es un elemento DOM
  ]),
  handleClose: PropTypes.func.isRequired,
  handleLanguageChanged: PropTypes.func.isRequired,
  i18nConfig: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LanguageMenuComponent.defaultProps = {
  anchor: null,
};

export default LanguageMenuComponent;
