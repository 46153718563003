import React, { useEffect, useState } from "react";
import { Paper, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SelectComponent from "../../../../shared/select/SelectComponent";
import { useIntl } from "react-intl";
import { getRooms, getSessionStatus, getPlenarySessions } from "../../../../../services/commonservices";
import withAuthorization from "../../../../common/auth/withAuthorization";
import {
  ROLE_TIIVII_SUPER_USER,
  ROLE_TIIVIIPLEN_ADMIN_USER,
  ROLE_TIIVIIPLEN_USER,
  ROLES_ADMIN,
  ROLES_ALL,
  SESSION_PLENARY__LIMIT,
} from "../../../../../utils/constants";
import { plenaryListColumns } from "../../plenaryListConstants";
import {
  ADD__PLENARY,
  PLENARY_LIST__FILTER_SELECT_DATE_FROM,
  PLENARY_LIST__FILTER_SELECT_DATE_TO,
  PLENARY_LIST__FILTER_SELECT_PLACE,
  PLENARY_LIST__FILTER_SELECT_STATUS,
  PLENARY_LIST__PAGE_DESCRIPTION,
  PLENARY_LIST__PAGE_TITLE,
  PLENARY_LIST__FILTER_SELECT_STATUS_ALL,
} from "../../../../../translations/constans";
import { BUTTON } from "../../../../shared/button/buttonConstants";
import ButtonComponent from "../../../../shared/button/ButtonComponent";
import { ROUTE_PLENARY_CREATION } from "../../../../../utils/routes";
import { useHistory } from "react-router-dom";
import ADD_CIRCLE_ICON from "../../../../../resources/AddCircleIcon";
import TypographyComponent from "../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_H1, TEXT_VARIANT_H3, TEXT_VARIANT_P } from "../../../../shared/typography/constants";
import { SELECT_VARIANT_BORDER } from "../../../../shared/select/SelectConstants";
import PlenaryListTableComponent from "./PlenaryListTable/PlenaryListTableComponent";
import RoomsComponent from "../../../../common/roomsComponent/RoomsComponent";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { hasRoles } from "../../../../../utils/keycloak";
import style from "./FilterPlenaryList.module.css";

const useStyles = makeStyles(() => ({
  room: {
    minWidth: "200px",
  },
}));

const FilterPlenaryListComponent = ({ styles }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [room, setRoom] = useState(null);

  const [sessionStatus, setSessionStatus] = useState(null);

  const [sessionStatusList, setSessionStatusList] = useState([]);

  const [plenarySessions, setPlenarySessions] = useState([]);

  const [rooms, setRooms] = useState([]);

  const [initDate, setInitDate] = useState(null);

  const [endDate, setEndDate] = useState(null);
  const [offset, setOffset] = useState(0);
  const [paginationTotalResults, setPaginationTotalResults] = useState("");
  const history = useHistory();
  const keycloak = useSelector((state) => state.common.keycloak);

  const handlePlenarySessions = (newPlenarySessions) => {
    setPlenarySessions(newPlenarySessions);
  };
  const handleSessionStatusList = (data) => {
    setSessionStatusList(data);
  };
  const handleSessionStatus = (newSessionStatus) => {
    setSessionStatus(newSessionStatus);
  };

  const handleRoom = (newRoom) => {
    setRoom(newRoom);
  };

  const handleInitDate = (date) => {
    setInitDate(date);
  };
  const handleEndDate = (data) => {
    setEndDate(data);
  };
  const handleRooms = (data) => {
    setRooms(data);
  };

  const handleSessionStatusChange = (newSessionStatus) => {
    const filters = {
      session_status: newSessionStatus.target.value,
      offset: 0,
      limit: SESSION_PLENARY__LIMIT,
    };
    if (!!room) {
      filters.plenary_room_id = room;
    }
    if (!!initDate) {
      filters.init_date = initDate;
    }
    if (!!endDate) {
      filters.end_date = endDate;
    }
    getPlenarySessions(filters, (response) => {
      handlePlenarySessions(response.data);
      handleSessionStatus(filters.session_status);
      setPaginationTotalResults(response?.headers["pagination-total-results"]);
    });
  };

  const handleSetSelectedRoom = (newRoomId) => {
    const filters = {
      plenary_room_id: newRoomId,
      offset: 0,
      limit: SESSION_PLENARY__LIMIT,
    };
    if (!!initDate) {
      filters.init_date = initDate;
    }
    if (!!endDate) {
      filters.end_date = endDate;
    }
    if (!!sessionStatus) {
      filters.session_status = sessionStatus;
    }
    getPlenarySessions(filters, (response) => {
      handlePlenarySessions(response.data);
      setPaginationTotalResults(response?.headers["pagination-total-results"]);
    });
    handleRoom(newRoomId);
  };

  const handleInitDateChange = (data) => {
    const filters = {
      init_date: data,
      offset: 0,
      limit: SESSION_PLENARY__LIMIT,
    };
    if (!!room) {
      filters.plenary_room_id = room;
    }
    if (!!endDate) {
      filters.end_date = endDate;
    }
    if (!!sessionStatus) {
      filters.session_status = sessionStatus;
    }
    getPlenarySessions(filters, (response) => {
      handlePlenarySessions(response.data);
      handleInitDate(data);
      setPaginationTotalResults(response?.headers["pagination-total-results"]);
    });
  };
  const handleEndDateChange = (data) => {
    const filters = {
      end_date: data,
      offset: 0,
      limit: SESSION_PLENARY__LIMIT,
    };
    if (!!room) {
      filters.plenary_room_id = room;
    }
    if (!!initDate) {
      filters.init_date = initDate;
    }
    if (!!sessionStatus) {
      filters.session_status = sessionStatus;
    }
    getPlenarySessions(filters, (response) => {
      handlePlenarySessions(response.data);
      handleEndDate(data);
      setPaginationTotalResults(response?.headers["pagination-total-results"]);
    });
  };

  const handleAllChange = () => {
    const filters = {
      offset: 0,
      limit: SESSION_PLENARY__LIMIT,
    };
    if (!!endDate) {
      filters.end_date = endDate;
    }
    if (!!room) {
      filters.plenary_room_id = room;
    }
    if (!!initDate) {
      filters.init_date = initDate;
    }
    if (!!sessionStatus) {
      filters.session_status = sessionStatus;
    }
    getPlenarySessions(filters, (response) => {
      handlePlenarySessions(response.data);
      setPaginationTotalResults(response?.headers["pagination-total-results"]);
    });
  };

  const handleRoomsSuccess = (response) => {
    const { data } = response;
    if (data.length === 1) {
      getPlenarySessions(
        { plenary_room_id: data[0].id, offset: offset, limit: SESSION_PLENARY__LIMIT },
        (sessionsResponse) => {
          handlePlenarySessions(sessionsResponse.data);
          handleRooms(data);
          setPaginationTotalResults(response?.headers["pagination-total-results"]);
        },
      );
    } else {
      getPlenarySessions({ offset: offset, limit: SESSION_PLENARY__LIMIT }, (response) => {
        handlePlenarySessions(response.data);
        handleRooms(data);
        setPaginationTotalResults(response?.headers["pagination-total-results"]);
      });
    }
  };

  useEffect(() => {
    getRooms((response) => handleRoomsSuccess(response));
  }, []);

  useEffect(() => {
    getSessionStatus((response) => handleSessionStatusList(response.data));
  }, []);

  const handleGoCreatePlenary = () => {
    history.push(ROUTE_PLENARY_CREATION);
  };

  const handleOffset = (page) => {
    const offsetPerPage = page * SESSION_PLENARY__LIMIT;
    setOffset(offsetPerPage);
  };
  const totalFiltersPlenarySession = () => {
    return {
      plenary_room_id: room,
      init_date: initDate,
      end_date: endDate,
      session_status: sessionStatus,
      offset: offset,
      limit: SESSION_PLENARY__LIMIT,
    };
  };
  useEffect(() => {
    getPlenarySessions(totalFiltersPlenarySession(), (response) => {
      handlePlenarySessions(response.data);
      setPaginationTotalResults(response?.headers["pagination-total-results"]);
    });
  }, [offset]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.headerPageContainer}>
        <div className={styles.titlePageContainer}>
          <TypographyComponent text={intl.formatMessage({ id: PLENARY_LIST__PAGE_TITLE })} variant={TEXT_VARIANT_H1} />
          <TypographyComponent
            text={intl.formatMessage({ id: PLENARY_LIST__PAGE_DESCRIPTION })}
            variant={TEXT_VARIANT_H3}
          />
        </div>
        {hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER]) && (
          <ButtonComponent
            text={intl.formatMessage({ id: ADD__PLENARY })}
            variant={BUTTON.VARIANT_PRIMARY}
            size={BUTTON.SIZE__FIT_CONTENT}
            onClick={handleGoCreatePlenary}
            icon={ADD_CIRCLE_ICON}
          />
        )}
      </div>
      <Paper className={styles.table}>
        <div className={styles.filterGroupContainer}>
          <div>
            <TypographyComponent
              text={intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_PLACE })}
              variant={TEXT_VARIANT_P}
            />
            {rooms.length === 1 ? (
              <div className={style.textRoomField}>{rooms[0].name}</div>
            ) : (
              <div className={classes.room}>
                <RoomsComponent selectedRoom={room} setSelectedRoom={handleSetSelectedRoom} />
              </div>
            )}
          </div>
          <div>
            <TypographyComponent
              text={intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_STATUS })}
              variant={TEXT_VARIANT_P}
            />
            {sessionStatusList.length > 1 ? (
              <div>
                <SelectComponent
                  data={sessionStatusList}
                  defaultValue={sessionStatus}
                  fieldLabel={"name"}
                  fieldLValue={"codename"}
                  handleOnchange={handleSessionStatusChange}
                  variant={SELECT_VARIANT_BORDER}
                  labelOption={intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_STATUS_ALL })}
                />
              </div>
            ) : (
              <div className={style.textStatusFilter}>
                {intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_STATUS_ALL })}
              </div>
            )}
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TypographyComponent
                text={intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_DATE_FROM })}
                variant={TEXT_VARIANT_P}
              />
              <DesktopDatePicker
                format="dd-MM-yyyy"
                value={initDate}
                onChange={handleInitDateChange}
                renderInput={(params) => <TextField {...params} />}
                slotProps={{
                  field: { clearable: true },
                }}
              />
            </LocalizationProvider>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TypographyComponent
                text={intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_DATE_TO })}
                variant={TEXT_VARIANT_P}
              />
              <DesktopDatePicker
                format="dd-MM-yyyy"
                value={endDate}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} />}
                slotProps={{
                  field: { clearable: true },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <PlenaryListTableComponent
          columns={plenaryListColumns(intl)}
          rows={plenarySessions}
          onReloadData={handleAllChange}
          handleOffset={handleOffset}
          paginationTotalResults={paginationTotalResults}
          offset={offset}
          limit={SESSION_PLENARY__LIMIT}
        />
      </Paper>
    </div>
  );
};

/*const rows = [{
    end_date: 1698062400,
    id: 1,
    init_date: 1698044400,
    status: "SCHEDULED",
    subtitle: "Para autonomos y pequeñas empresas",
    title: "Concesion de ayudas 2023"
}]*/
export default withAuthorization(ROLES_ALL)(FilterPlenaryListComponent);
