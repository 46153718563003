import React from "react";
import { useIntl } from "react-intl";
import { Grid } from "@mui/material";
import {
  COMMON__ALL_RIGHTS_RESERVED,
  COMMON__PRIVACY_POLICY,
  COMMON__TERMS_OF_USE,
} from "../../../translations/constans";
import { CINFO_IMAGE_ALT, CINFO_NAME, COPYRIGHT_STRING } from "./constants";
import { BACK_SLASH, LINK_TARGET_BLANK, LINK_TARGET_NO_REFER } from "../../../utils/constants";
import logoCinfoFooter from "../../../images/logo_cinfo.svg";
import styles from "./FooterComponent.module.css";
const FooterComponent = () => {
  const intl = useIntl();
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className={styles.footer}>
      <Grid container>
        <Grid item sm={6} md={2} className={styles.logoFooter}>
          <div>
            <img alt={CINFO_IMAGE_ALT} src={logoCinfoFooter} className={styles.imageHeader} />
          </div>
        </Grid>
        <Grid item sm={6} md={10} className={styles.legalText}>
          <div className={styles.copyright}>{`${COPYRIGHT_STRING} ${year} ${CINFO_NAME}`}</div>
          <div>
            {intl.formatMessage({ id: COMMON__ALL_RIGHTS_RESERVED })}
            <b>
              <a href={BACK_SLASH} target={LINK_TARGET_BLANK} rel={LINK_TARGET_NO_REFER}>
                {intl.formatMessage({ id: COMMON__PRIVACY_POLICY })}
              </a>
            </b>
            <b>
              <a href={BACK_SLASH} target={LINK_TARGET_BLANK} rel={LINK_TARGET_NO_REFER}>
                {intl.formatMessage({ id: COMMON__TERMS_OF_USE })}
              </a>
            </b>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FooterComponent;
