import React from "react";
import { formatThreeDigits, formatTwoDigits, getObjectTime } from "../../../utils/utils";
import style from "./TimeComponent.module.css";

const TimeComponent = ({ timeMilliseconds, timeText }) => {
  const { hours, minutes, seconds, milliseconds } = getObjectTime(timeMilliseconds);
  return (
    <>
      <div className={style.textTile}>{timeText}:</div>
      <div>
        {formatTwoDigits(hours)} : {formatTwoDigits(minutes)} : {formatTwoDigits(seconds)}
      </div>
    </>
  );
};
export default TimeComponent;
