import React, { useEffect, useState } from "react";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

import { SEVERITY_ERROR, SEVERITY_INFO } from "../../../utils/constants";
import { isString } from "../../../utils/utils";
import {
  ANCHOR_HORIZONTAL,
  ANCHOR_VERTICAL,
  AUTO_HIDE_DURATION,
  EMPTY_STRING,
  SNACK_BAR_ARIAL_COLOR,
  SNACK_BAR_ARIAL_LABEL,
  SNACK_BAR_ICON_KEY,
  SNACK_BAR_PROPERTY,
  SNACK_BAR_VALUE,
} from "./constants";

const useStyles = makeStyles((theme) => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

let setMessageFn;

const NotificationSnackComponent = () => {
  const classes = useStyles();

  const [notification, setNotification] = useState({
    open: false,
    message: EMPTY_STRING,
    severity: SEVERITY_INFO,
  });

  const handleClose = () => {
    setNotification({
      ...notification,
      open: false,
      message: EMPTY_STRING,
    });
  };

  const setMessage = (m, s) => {
    let severity = !!s ? s : notification?.severity;
    let message = EMPTY_STRING;

    try {
      const error = JSON.parse(m);
      if (!!error && !!error.errorCode && !!error.messageES) {
        message = error.messageES;
        severity = SEVERITY_ERROR;
      }
    } catch (e) {
      if (!!m && !!m.message) {
        message = m.message;
      } else if (isString(m)) {
        message = m;
      } else {
        message = JSON.stringify(m);
      }
    }

    setNotification({
      open: true,
      message,
      severity,
    });
  };

  useEffect(() => {
    setMessageFn = setMessage;
  }, []);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: ANCHOR_VERTICAL,
          horizontal: ANCHOR_HORIZONTAL,
        }}
        open={notification.open}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={handleClose}
        ContentProps={{
          [SNACK_BAR_PROPERTY]: SNACK_BAR_VALUE,
        }}
        action={[
          <IconButton
            key={SNACK_BAR_ICON_KEY}
            aria-label={SNACK_BAR_ARIAL_LABEL}
            color={SNACK_BAR_ARIAL_COLOR}
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      >
        <Alert onClose={handleClose} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export function showMessage(message, severity) {
  setMessageFn(message, severity);
}

export default NotificationSnackComponent;
