import React, { useEffect, useState } from "react";
import { getSessionInfo } from "../../../../../../../services/commonservices";
import VideoPlayerComponent from "../../../plenaryDetailMinuteTranscriptionComponent/components/videoPlayerComponent/VideoPlayerComponent";
import AutomaticProduction from "./components/automaticProductionComponent/AutomaticProductionComponent";
import { hasRoles } from "../../../../../../../utils/keycloak";
import { useIntl } from "react-intl";
import {
    ROLE_TIIVII_SUPER_USER,
    ROLE_TIIVIIPLEN_ADMIN_USER,
    ROLE_TIIVIIPLEN_USER,
    SESSION__PLENARY__STATUS__FINISHED,
    SESSION__PLENARY__STATUS__IN_REVIEW,
    SESSION__PLENARY__STATUS__PUBLISHED,
    SESSION__PLENARY__STATUS__REVIEWED,
} from "../../../../../../../utils/constants";
import { useSelector } from "react-redux";
import { Paper, Tooltip } from "@mui/material";
import StatusChangeComponent from "../../../plenaryDetailMinuteTranscriptionComponent/components/statusChangeComponent/StatusChangeComponent";
import style from "./VideoAreaComponent.module.css";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import ButtonComponent from "../../../../../../shared/button/ButtonComponent";
import { COMMON__DOWNLOAD_VIDEO, COMMON__DOWNLOAD_AUDIO_PLENO, COMMON__AUDIO_UNAVAILABLE } from "../../../../../../../translations/constans";
import { BUTTON } from "../../../../../../shared/button/buttonConstants";

const VideoAreaComponent = ({
    plenarySessionId,
    url,
    videoRef,
    editableByStatus,
    onReloadData,
    showVideoPlayer = true,
}) => {
    const intl = useIntl();
    const keycloak = useSelector((state) => state.common.keycloak);
    const sessionData = useSelector((state) => state.sessionDetail.sessionDetailData);

    const [isMouseOver, setIsMouseOver] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);

    const handleMouseOver = () => {
        setIsMouseOver(true);
    };

    const handleMouseOut = () => {
        setIsMouseOver(false);
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const downloadAudio = () => {
        if (sessionData?.status === SESSION__PLENARY__STATUS__REVIEWED) {
            const audioUrl = sessionData?.video?.audio_url;
            if (audioUrl) {
                const link = document.createElement("a");
                link.href = audioUrl;
                link.download = "audio.mp3";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const downloadFile = (file) => {
        const fileName = file.substring(file.lastIndexOf("/") + 1);
        const link = document.createElement("a");
        link.href = file;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Paper
            elevation={4}
            className={`${style.videoAreaContainer} ${isMouseOver && isMouseDown ? style.hovered : ""}`}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            <>
                <div className={style.videoControls}>
                    {hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER, ROLE_TIIVIIPLEN_USER]) &&
                        (sessionData?.status === SESSION__PLENARY__STATUS__FINISHED ||
                            sessionData?.status === SESSION__PLENARY__STATUS__IN_REVIEW ||
                            sessionData?.status === SESSION__PLENARY__STATUS__REVIEWED ||
                            sessionData?.status === SESSION__PLENARY__STATUS__PUBLISHED) ? (
                        <StatusChangeComponent
                            currentStatus={sessionData.status}
                            plenarySessionId={plenarySessionId}
                            onReloadData={onReloadData}
                        />
                    ) : (
                        <AutomaticProduction
                            plenarySessionId={plenarySessionId}
                            sessionData={sessionData}
                            editableByStatus={editableByStatus}
                        />
                    )}
                    {sessionData?.video?.download_urls?.length > 0 && (
                        <div className={style.downloadSection}>
                            <div className={style.downloadHeader}>
                                <strong>{intl.formatMessage({ id: COMMON__DOWNLOAD_VIDEO })}</strong>
                            </div>
                            <div className={style.downloadButtons}>
                                {sessionData?.video?.download_urls?.map((download, index) => {
                                    return (
                                        <ButtonComponent
                                            key={index.toString()}
                                            onClick={() => downloadFile(download.download_url)}
                                            text={download.quality + "p"}
                                            variant={BUTTON.VARIANT_SECONDARY}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {sessionData?.status === SESSION__PLENARY__STATUS__REVIEWED && (
                        <div className={style.downloadSection}>
                            <div className={style.downloadHeader}>
                                <strong>{intl.formatMessage({ id: COMMON__DOWNLOAD_AUDIO_PLENO })}</strong>
                            </div>
                            <Tooltip title={sessionData?.video?.audio_url ? "" : intl.formatMessage({ id: COMMON__AUDIO_UNAVAILABLE })} placement="bottom">
                                <span>
                                    <ButtonComponent
                                        onClick={downloadAudio}
                                        text="MP3"
                                        variant={sessionData?.video?.audio_url ? BUTTON.VARIANT_SECONDARY : BUTTON.VARIANT_DISABLE}
                                        disabled={!sessionData?.video?.audio_url}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                    )}
                </div>
                {showVideoPlayer ? (
                    <VideoPlayerComponent videoRef={videoRef} subtitles={sessionData?.video?.subtitles} videoUrl={url} />
                ) : (
                    <div className={style.noVideo}>
                        <VideocamOffIcon sx={{ color: "#e30613", fontSize: 140 }} />
                    </div>
                )}
            </>
        </Paper>
    );
};

export default VideoAreaComponent;
